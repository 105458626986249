import * as Actions from '../actions';

let user = JSON.parse(localStorage.getItem('user'));
const initialAuthState = user ? { loggingIn: false, loggedIn: true, user } : { loggingIn: false, loggedIn: false, user: {} };

export const authentication = (state = initialAuthState, action) => {
	switch(action.type) {
		case Actions.AUTH_LOGIN_REQUEST:
			return {
				loggingIn: true,
				user: action.payload.user
			};
		case Actions.AUTH_LOGIN_SUCCESS:
			return {
				loggingIn: false,
				loggedIn: true,
				user: action.payload.user
			};
		case Actions.AUTH_LOGIN_FAILURE:
			return {
				loggingIn: false,
				loggedIn: false
			};
		case Actions.AUTH_LOGOUT:
			return {
				loggingIn: false,
				loggedIn: false
			};
		default:
			return state;
	}
};
