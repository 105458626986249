import React, { Component } from 'react';
import Table from '../components/Table';
import PageSection from '../components/PageSection';
import FilterMenu from '../components/FilterMenu';
import BuilderFilter from '../components/BuilderFilter';
import BuilderSelect from '../components/BuilderSelect';
import ModelSelect from '../components/ModelSelect';
import DealerPopup from '../components/DealerPopup';
import Page from '../components/Page';
import Pagination from '../components/Pagination';
import renderHTML from 'react-render-html';
import history from '../history';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { modelActions, paginationActions } from '../actions';

const defaultPagination = {
	page: 1,
	perPage: 1000,
	filters: {},
	hiddenFilters: { has_refits: true }
};

const paginationScope = 'refitListing';

class RefitListingCompare extends Component {
	constructor(props) {
		super(props);

		this.state = {
			urlBasedFilter: true,
			dealerPopup: false,
			showFilter: false,
			tableHeading: [
				'Model'
			],

		};
		this.toggleFilter = this.toggleFilter.bind(this);
		this.applyFilters = this.applyFilters.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.sortChange = this.sortChange.bind(this);
	}

	componentDidMount() {
		this.props.initPagination(paginationScope);
		// set state variables on component mount
		let query = queryString.parse(history.location.search);

		if(Object.entries(query).length > 0) {
			let { page, perPage, ...filters } = query;
			let setPage = page || defaultPagination.page;
			let setPerPage = 1000;
			let hiddenFilters = defaultPagination.hiddenFilters;

			this.props.setPagination(
				paginationScope,
				setPage,
				setPerPage,
				filters,
				hiddenFilters)
			.then(() => {
				this.props.search(setPage, setPerPage, {...filters, ...hiddenFilters});
				this.props.searchPP();
			});
		} else {
			this.props.setPagination(
				paginationScope,
				defaultPagination.page,
				defaultPagination.perPage,
				defaultPagination.filters,
				defaultPagination.hiddenFilters)
			.then(() => {
				this.props.search(this.props.page, this.props.perPage, {...this.props.filters, ...this.props.hiddenFilters});
				this.props.searchPP();
			});
		}
	}

	toggleFilter() {
		this.setState({
			showFilter: !this.state.showFilter
		});
	}

	applyFilters() {
		// reset pagination numbers
		this.props.setPagination(
			paginationScope,
			defaultPagination.page,
			this.props.perPage,
			this.props.filters,
			this.props.hiddenFilters)
		.then(() => {
			const { page, perPage, filters, hiddenFilters } = this.props;
			let filterString = queryString.stringify(filters);
			if (filterString !== '') {
				filterString = '&' + filterString;
			}
			this.props.search(page, perPage, {...filters, ...hiddenFilters});
			history.push({
				search: '?page=' + page + '&perPage=' + perPage + filterString
			});
		});

		this.setState({
			showFilter: false
		});
	}

	clearFilters() {
		this.props.clearFilters(paginationScope).then(() => {
			const { page, perPage, filters, hiddenFilters } = this.props;
			let filterString = queryString.stringify(filters);
			if (filterString !== '') {
				filterString = '&' + filterString;
			}
			this.props.search(page, perPage, {...filters, ...hiddenFilters});
			history.push({
				search: '?page=' + page + '&perPage=' + 1000 + filterString
			});
		});

		this.setState({
			showFilter: false
		});
	}

	sortChange(e) {
		const { name, value } = e.target;
		this.props.filterUpdate(paginationScope, name, value).then(() => {
			this.applyFilters();
		});
	}

	render() {
		const { loading, refits, filters, models, modelsPP } = this.props;
		const { showFilter } = this.state;
		const loadingMessage = <p className="sk-loading">Loading</p>;

		let clearFilters;
		let filtersAdded  = (Object.entries(filters).length > 0);
		let filterText = 'Filter';
		let filterClass = 'sk-button rf-filter-button';
		let filterAction = this.applyFilters

		if(showFilter && filtersAdded) {
			filterText = 'Apply Filters';
			filterClass = 'sk-button rf-filter-button rf-apply-filters';
		} else if (showFilter) {
			filterText = 'Back';
		} else {
			filterText = 'Filter';
			filterAction = this.toggleFilter;
		}

		let filterButton = <button className={filterClass} onClick={filterAction}><span className="rf-filter-button-text">{filterText}</span></button>

		// check if any filters have been added
		if (filtersAdded) {
			clearFilters = <button className="sk-clear-filters" onClick={this.clearFilters}>Clear Filters</button>
		}

		let sectionActions = '';


		let modelsArray = [],
			modelsArrayPP = [],
			tableArray = [],
			tableArrayPP =[],
			currBuilder = '';

		for(let i = 0; i < models.length; i++) {
			if (currBuilder != models[i].builder.builder_name && currBuilder != '') {
				tableArray.push(
					<>
						<h2 className="sk-heading-3 rf-builder-title">{currBuilder}</h2>
						<Table
							tableHeading={this.state.tableHeading}
							tableBody={modelsArray}
							noResultsMessage="No Results Found"
							loadingMessage={loadingMessage}
							loading={loading}
						/>
					</>
				);
				modelsArray = [];
			}
			modelsArray.push([models[i].model_name]);
			currBuilder = models[i].builder.builder_name;
		}

		if (modelsPP != undefined) {
			currBuilder = '';
			for(let i = 0; i < modelsPP.length; i++) {
				if (currBuilder != modelsPP[i].builder_name && currBuilder != '') {
					tableArrayPP.push(
						<>
							<h2 className="sk-heading-3 rf-builder-title">{currBuilder}</h2>
							<Table
								tableHeading={this.state.tableHeading}
								tableBody={modelsArrayPP}
								noResultsMessage="No Results Found"
								loadingMessage={loadingMessage}
								loading={loading}
							/>
						</>
					);
					modelsArrayPP = [];
				}
				modelsArrayPP.push([modelsPP[i].model_name]);
				currBuilder = modelsPP[i].builder_name;
			}
		}

		var sectionTitle = "Data Comparison";
		var sectionTitleClass = (showFilter === false) ? "sk-pencil" : "sk-filter";

		return (
			<Page pageClass="rf-refits-listing rf-compare">
				<PageSection
					sectionTitle={sectionTitle}
					sectionActions={sectionActions}
				>
					<header className="rf-table-header">
						<h2 className="sk-heading-2 rf-fixed">NetSuite</h2>
						<h2 className="sk-heading-2 rf-fixed">SK Database</h2>
					</header>

					<div className="refit-listing-table rf-double">

						{tableArray.map((value, index) => (
							<div>{value}</div>
						))}

					</div>

					<div className="refit-listing-table rf-double">

						{tableArrayPP.map((value, index) => (
							<div>{value}</div>
						))}

					</div>

				</PageSection>
			</Page>
		);
	}
}

function mapStateToProps(state) {
	let page = defaultPagination.page;
	let perPage = defaultPagination.perPage;
	let filters = defaultPagination.filters;
	let hiddenFilters = defaultPagination.hiddenFilters;
	if(state.paginationReducer[paginationScope]) {
		page = state.paginationReducer[paginationScope].page;
		perPage = state.paginationReducer[paginationScope].perPage;
		filters = state.paginationReducer[paginationScope].filters;
		hiddenFilters = state.paginationReducer[paginationScope].hiddenFilters;
	}

	return {
		refits: state.refitReducer.refits,
		loading: state.modelReducer.loading,
		user: state.authentication.user,
		models: state.modelReducer.models,
		modelsPP: state.modelReducer.modelsPP,
		page,
		perPage,
		filters,
		hiddenFilters,
	};
}

const mapDispatchToProps = {
	search: modelActions.search,
	searchPP: modelActions.searchPP,
	clearFilters: paginationActions.reset,
	filterUpdate: paginationActions.updateFilter,
	setFilters: paginationActions.setFilters,
	setPagination: paginationActions.setPagination,
	initPagination: paginationActions.init
};

export default connect(mapStateToProps, mapDispatchToProps)(RefitListingCompare);
