export const authService = {
	login,
	logout
};

function login(username, password) {
	let requestBody = new FormData();
	requestBody.append('user_email', username);
	requestBody.append('user_password', password);
	requestBody = new URLSearchParams(requestBody);

	const requestURL = process.env.REACT_APP_API_URL + '/pp-users/login';
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
		body: requestBody
	};

	return fetch(requestURL, requestOptions)
		.then(handleResponse)
		.then(
			(json) => {
				if(json.token && json.user) {
					json.user.token = json.token;
					setUser(json.user);
				}

				return json.user;
			}
		);
}

function logout() {
	localStorage.removeItem('user');
}

function setUser(user) {
	localStorage.setItem('user', JSON.stringify(user));
}

function handleResponse(response) {
	return response.json().then((json) => {
		if(json.success) {
			return json;
		} else {
			return Promise.reject(json.messages);
		}
	});

	// return response.text().then(text => {
	// 	const data = text && JSON.parse(text);
	// 	if (!response.ok) {
	// 		if (response.status === 401) {
	// 			// auto logout if 401 response returned from api
	// 			logout();
	// 			location.reload(true);
	// 		}
	//
	// 		const error = (data && data.message) || response.statusText;
	// 		return Promise.reject(error);
	// 	}
	//
	// 	return data;
	// });
}
