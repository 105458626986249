import { authHeader } from '../helpers';
import queryString from 'query-string';

export const modelService = {
	search,
	searchPP,
	update,
	create,
	get
};

function search(page, perPage, filters) {
	// const query = 'page=' + page + '&per_page=' + perPage + '&' + queryString.stringify(filters);
	// const requestURL = process.env.REACT_APP_API_URL + '/boat-models?' + query;
	// console.log(requestURL);
	// const requestOptions = {
	// 	method: 'GET',
	// 	headers: authHeader()
	// };
	//
	// return fetch(requestURL, requestOptions).then(handleResponse);

	const query = 'page=' + page + '&per_page=' + perPage + '&' + queryString.stringify(filters);
	const requestURL = process.env.REACT_APP_API_URL + '/netsuite-refits/boat-models?' + query;
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function searchPP(page, perPage, filters) {
	const requestURL = process.env.REACT_APP_API_URL + '/model-list';
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function update(model) {
	const requestBody = JSON.stringify({model: model});
	const requestURL = process.env.REACT_APP_API_URL + '/boat-models/' + model.model_id;
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: requestBody
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function create(model) {
	const requestBody = JSON.stringify({model: model});
	const requestURL = process.env.REACT_APP_API_URL + '/boat-models';
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: requestBody
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function get(modelID, builder_id) {
	// const requestURL = process.env.REACT_APP_API_URL + '/boat-models/' + modelID;
	// const requestOptions = {
	// 	method: 'GET',
	// 	headers: authHeader()
	// };
	//
	// return fetch(requestURL, requestOptions).then(handleResponse);

	const requestURL = process.env.REACT_APP_API_URL + '/netsuite-refits/boat-models/' + modelID + '?builder_id=' + builder_id;
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function handleResponse(response) {
	return response.json().then((json) => {
		if(json) {
			return json;
		} else {
			return Promise.reject(json.messages);
		}
	});
}
