import { authHeader } from '../helpers';

export const applicationService = {
	get
};

function get() {
	const requestURL = process.env.REACT_APP_API_URL + '/boat-applications';
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function handleResponse(response) {
	return response.json().then((json) => {
		if(json.success) {
			return json;
		} else {
			return Promise.reject(json.messages);
		}
	});
}
