import React, { Component } from 'react';
import Boat from '../assets/images/global/boat.png';
import Page from '../components/Page';
import PageSection from '../components/PageSection';
import NoResults from '../components/NoResults';
import { connect } from 'react-redux'
import { refitActions } from '../actions';
import renderHTML from 'react-render-html';
import seatrialImage from '../assets/images/global/seatrial-image.png';
import { USER_ROLES } from '../constants';

class RefitSingle extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		const { refit_id } = this.props.match.params;
		this.props.get(refit_id);
	}

	render() {
		const { refit, user } = this.props;
		var seaTrialLinks = [];
		var drawingsList = [];
		var showPerformance = false;
		var boatImage = false;
		let showHours = false;

		if (typeof refit.config !== 'undefined' || typeof refit.boat !== 'undefined') {

			var configs = refit.config,
				seakeeperModels = [],
				seakeeperModelImages = [],
				seaTrials = refit.sea_trials,
				drawings = refit.drawings || [],
				hours = refit.refit_hours || '',
				sizingFormList = refit.sizing_form,
				boat = refit.boat,
				address1 = '',
				address2 = '';

			if(refit.boat.images && refit.boat.images.length > 0) {
				boatImage = refit.boat.images[0].file_name;
			}

			if(refit.dealer && refit.dealer.dealer_address) {
				address1 = refit.dealer.dealer_address.split("\n")[0];
				address2 = refit.dealer.dealer_address.split("\n")[1];
			}

			for (let a = 0; a < configs.length; a += 1) {
				seakeeperModels.push( configs[a].seakeeper.seakeeper_model + (configs[a].sk_model_count > 1 ? ' (x' + configs[a].sk_model_count + ')' : '') );
				seakeeperModelImages.push( process.env.REACT_APP_API_URL + '/' + configs[a].seakeeper.image_url);
			}

			for (let a = 0; a < seaTrials.length; a += 1) {
				seaTrialLinks.push(<a href={"https://seakeeper.com/performance/sea-trials/?trial_id=" + seaTrials[a].trial_id} target="_blank" rel="noopener noreferrer">{"Sea Trial " + seaTrials[a].trial_time}</a>);
			}

			if(user.pp_role_id <= USER_ROLES.DEALER && drawings) {
				for (let a = 0; a < drawings.length; a++) {
					drawingsList.push(<p className="rf-drawing-link">{boat.bmy.model.builder.builder_name + ' ' + boat.bmy.model.model_name} - Install Drawing {a + 1} <a href={drawings[a].file_name} target="_blank" rel="noopener noreferrer">Download</a></p>);
				}
				if(refit.install_photos_link) {
					drawingsList.push(<a href={refit.install_photos_link} target="_blank">Dropbox Link</a>);
				}
			}

			seakeeperModels = seakeeperModels.join(', ');

			var sizingForm = '';
			if(sizingFormList.length > 0 && (user.pp_role_id <= USER_ROLES.ADMIN || user.pp_user_id === sizingFormList[0].report.created_by)) {
				if (sizingFormList.length > 0) {
					sizingForm = sizingFormList[0].sizing_form_id;
				}
			}

			if(seaTrialLinks.length > 0 || sizingForm !== '') {
				showPerformance = true;
			}

			if(hours !== '' && hours !== 0) {
				showHours = true;
			}

		} else {
			return (
				<Page pageClass="rf-refits-single">
					<PageSection>
						<div className="rf-single-header">
							<a href="/refits-search" className="rf-page-back">&lt; Refits Database</a>
							<h1 className="rf-heading-1">Refits Details</h1>
						</div>

						<NoResults message={<p className="sk-loading">Loading</p>} />
					</PageSection>
				</Page>
			)
		}


		return (
			<Page pageClass="rf-refits-single">

				<PageSection>
					<div className="rf-single-header">
						<a href="/refits-search" className="rf-page-back">&lt; Refits Database</a>
						<h1 className="rf-heading-1">Refits Details</h1>
					</div>
					{typeof refit.config === 'undefined' &&
						<NoResults message={<p className="sk-loading">Loading</p>} />
					}
					{typeof refit.config !== 'undefined' &&
					<>
						<div className="rf-refit-data-block">

							<div className="rf-images rf-seakeeper-image">
								{seakeeperModelImages.map((image, index) => (
									<img src={image} className="rf-image" alt="Seakeeper model" />
								))}
							</div>

							<div className="rf-data-points">
								<h2 className="rf-data-title">Refit Details</h2>
								<dl className="rf-data-list">

									<dt className="rf-title">Date of Install</dt>
									<dd className="rf-data">{refit.start_date} - {refit.end_date}</dd>

									{showHours &&
										<>
											<dt className="rf-title">Installation Hours</dt>
											<dd className="rf-data">{refit.refit_hours}</dd>
										</>
									}
									<dt className="rf-title">Seakeeper Model</dt>
									<dd className="rf-data">{seakeeperModels}</dd>

									{refit.install_location &&
										<>
										<dt className="rf-title">Boat Install Location</dt>
										<dd className="rf-data">{refit.install_location}</dd>
										</>
									}

									{drawingsList.map((link, index) => (
									<>
										<dt className="rf-title">{index === 0 && "Boat Install Drawings"}</dt>
										<dd className="rf-data">{link}</dd>
									</>
									))}

								</dl>
							</div>
						</div>

						<div className="rf-refit-data-block">
							<div className="rf-images rf-boat-image">
								{boatImage &&
									<img src={boatImage} className="rf-image" alt={boat.boat_name} />
								}
							</div>
							<div className="rf-data-points">
								<h2 className="rf-data-title">Boat Specifications</h2>
								<dl className="rf-data-list">

									<dt className="rf-title">Make</dt>
									<dd className="rf-data">{boat.bmy.model.builder.builder_name}</dd>

									<dt className="rf-title">Model</dt>
									<dd className="rf-data">{boat.bmy.model.model_name}</dd>

									{boat.bmy.boat_year &&
										<>
											<dt className="rf-title">Year</dt>
											<dd className="rf-data">{boat.bmy.boat_year}</dd>
										</>
									}

									{boat.boat_name &&
										<>
											<dt className="rf-title">Boat Name</dt>
											<dd className="rf-data">{boat.boat_name}</dd>
										</>
									}

									{boat.bmy.loa &&
										<>
											<dt className="rf-title">Length Overall</dt>
											<dd className="rf-data">{boat.bmy.loa}m</dd>
										</>
									}

									{boat.bmy.boa &&
										<>
											<dt className="rf-title">Beam Overall</dt>
											<dd className="rf-data">{boat.bmy.boa}m</dd>
										</>
									}

									{boat.bmy.displacement &&
										<>
											<dt className="rf-title">Displacement</dt>
											<dd className="rf-data">{boat.bmy.displacement}kgs</dd>
										</>
									}

									{(boat.bmy.troll || boat.bmy.troll_est) &&
										<>
											<dt className="rf-title">Natural Roll Period</dt>
											<dd className="rf-data">{boat.bmy.troll || boat.bmy.troll_est}s</dd>
										</>
									}

								</dl>
							</div>
						</div>

						{showPerformance &&
							<div className="rf-refit-data-block">
								<div className="rf-images">
									<img src={seatrialImage} alt="Sea Trial" className="rf-image" width="369" height="172" />
								</div>
								<div className="rf-data-points">
									<h2 className="rf-data-title">Performance</h2>
									<dl className="rf-data-list">

										{sizingForm !== '' &&
											<>
											<dt className="rf-title">Prediction Report</dt>
											<dd className="rf-data"><a href={"https://sizing.seakeeper.com/report/" + sizingForm} target="_blank" rel="noopener noreferrer">Sizing Form</a></dd>

											<dt className="rf-title"></dt>
											<dd className="rf-data"></dd>
											</>
										}


										{seaTrialLinks.map((link, index) => (
										<>
										<dt className="rf-title">{index === 0 && "Sea Trials"}</dt>
										<dd className="rf-data">{link}</dd>
										</>
										))}

									</dl>
								</div>
							</div>
						}

						<div className="rf-refit-data-block">
							<div className="rf-images">
								{refit.dealer.dealer_logo &&
									<img src={refit.dealer.dealer_logo} className="rf-image" alt={refit.dealer.dealer_name + " logo"} />
								}
							</div>
							<div className="rf-data-points">
								<h2 className="rf-data-title">Installer</h2>
								<dl className="rf-data-list">

									<dt className="rf-title">Company</dt>
									<dd className="rf-data">{refit.dealer.dealer_name}</dd>

									{address1 &&
										<>
											<dt className="rf-title">Address<span className="sk-visually-hidden"> Line 1</span></dt>
											<dd className="rf-data">{address1}</dd>
										</>
									}

									{address2 &&
										<>
											<dt className="rf-title"><span className="sk-visually-hidden">Address Line 2</span></dt>
											<dd className="rf-data">{address2}</dd>
										</>
									}

									{refit.dealer.dealer_contact_info.length > 1 &&
										<>
											<dt className="rf-title">Contact Info</dt>
											<dd className="rf-data">{renderHTML(refit.dealer.dealer_contact_info)}</dd>
										</>
									}

								</dl>
							</div>
						</div>

						{refit.customer_testimonial &&
							<div className="rf-refit-data-block rf-testimonial">
								<div className="rf-images">
								</div>
								<div className="rf-data-points">
									<h2 className="rf-data-title">Customer Testimonial</h2>
									<p className="rf-data-copy">{refit.customer_testimonial}</p>
								</div>
							</div>
						}
					</>
					}
				</PageSection>
			</Page>
		);
	}
}

function mapStateToProps(state) {
	return {
		refit: state.refitReducer.refit,
		loading: state.refitReducer.loading,
		user: state.authentication.user
	};
}

const mapDispatchToProps = {
	get: refitActions.get
};

export default connect(mapStateToProps, mapDispatchToProps)(RefitSingle);
//export default RefitSingle;
