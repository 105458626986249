import * as Actions from '../actions';

const initialSeaTrialState = {
	seaTrials: [],
	loading: false
};

export const seaTrialReducer = (state = initialSeaTrialState, action) => {
	switch(action.type) {
		case Actions.SEA_TRIAL_SEARCH_REQUEST:
			return {
				...state,
				loading: true
			};
		case Actions.SEA_TRIAL_SEARCH_SUCCESS:
			return {
				...state,
				loading: false,
				seaTrials: action.payload.seaTrials
			};
		default:
			return state;
	}
};
