import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SiteLogo from '../assets/images/global/seakeeper-logo.png';
import { authActions } from '../actions';
import { USER_ROLES } from '../constants';

class Header extends Component {
	constructor(props) {
		super(props);

		this.handleLogout = this.handleLogout.bind(this);
	}

	handleLogout(e) {
		this.props.logout();
	}

	render() {
		const { loggedIn, user } = this.props;
		console.log(user);

		let menuItems =
		<ul className="sk-site-menu">
			<li className="sk-menu-item-wrapper">
				<Link to="/refits-search/" className="sk-menu-item">Refits List</Link>
			</li>
		</ul>

		 let profile = <ul className="sk-profile-menu">
			 <li className="sk-menu-item-wrapper">
				 <Link to="/login"  className="sk-menu-item">Login</Link>
			 </li>
		 </ul>

		if(loggedIn) {

			if(user.pp_role_id <= USER_ROLES.ADMIN) {
				menuItems = <ul className="sk-site-menu">
					<li className="sk-menu-item-wrapper">
						<Link to="/refits-search/" className="sk-menu-item">Refit Reports</Link>
					</li>
				</ul>
			}


			profile = <ul className="sk-profile-menu">
				<li className="sk-menu-item-wrapper">
					<span className="sk-menu-item">Hello, {user.first_name && user.first_name}</span>
					<ul className="sk-sub-menu">
						<li className="sk-menu-item-wrapper">
							<span className="sk-menu-item" onClick={this.handleLogout}>Logout</span>
						</li>
					</ul>
				</li>
			</ul>
		}

		return (
			<div className="sk-site-header">
				<nav className="sk-site-nav">
					<a href="/" className="sk-site-link"><img src={ SiteLogo } className="sk-site-logo" alt="" /><p>Refits</p></a>
					{menuItems}
					{profile}
				</nav>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { loggedIn, user } = state.authentication;
	return {
		loggedIn,
		user
	};
}

const mapDispatchToProps = {
	logout: authActions.logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
