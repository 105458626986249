import React, { Component } from 'react';
import { connect } from 'react-redux'
import { modelActions, paginationActions } from '../actions';
import { modelService } from '../services';

class ModelSelect extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modelID: false,
			modelText: "",
			showList: true,
			showListItems: [],
			loading: false,
			modelTableHeading: [
				'Select model',
				' '
			],
			activeItem: -1
		};

		this.activeItemElement = null;
		this.listSize = (this.props.listSize * 1) || 5;

		this.onChange = this.onChange.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.selectModel = this.selectModel.bind(this);
		this.filterList = this.filterList.bind(this);
		this.resetModelList = this.resetModelList.bind(this);
		this.keyPress = this.keyPress.bind(this);
		this.hoverItem = this.hoverItem.bind(this);
	}

	componentDidUpdate(prevProps)  {
		if(prevProps.builder.builder_id !== this.props.builder.builder_id) {

			if(!this.props.builder.builder_id) {
				this.resetModelList();
				return;
			}
			if(this.props.filters.model_id) {
				let model_id = this.props.filters.model_id;
				modelService.get(model_id)
					.then(
						json => {
							this.setState({
								modelID: model_id,
								modelText: json.data.model_name,
								modelList: [json.data],
								loading: false
							});
						},
						error => {
							console.log('error');
							console.log(error);
						}
					);
			}
		}
	}

	resetModelList() {
		this.setState({
			modelID: false,
			modelText: "",
			modelList: [],
			loading: false
		});
	}

	onFocus(e) {
		this.setState({
			showList: true
		});
	}

	onBlur(e) {

	}

	keyPress(e) {
		let { activeItem } = this.state;
		const { modelList } = this.props;
		let listSize = modelList.length;

		let key = (e.keyCode || e.which),
			down = 40,
			up = 38,
			enter = 13,
			target = e.target;

		switch (key) {
			case down:
				if (activeItem < (listSize - 1)) {
					activeItem += 1;
					this.setState({
						activeItem: activeItem
					});
				}
				e.preventDefault();
				break;

			case up:
				if (activeItem > 0) {
					activeItem -= 1;
					this.setState({
						activeItem: activeItem
					});
				}
				e.preventDefault();
				break;

			case enter:
				e.preventDefault();

				if(activeItem > -1) {
					this.selectModel({ target: this.activeItemElement });
				} else {
					this.props.applyFilters();
					e.target.blur();
				}
				break;

			default:
				this.filterList(e);
				break;
		}
	}

	onChange(e) {
		const { name, value } = e.target;

		this.setState({
			modelText: value,
			showList: true,
			modelID: false
		});

		this.props.filterUpdate(this.props.scope, 'model_name', value);
	}

	filterList(e) {
		const { value } = e.target;

		if(value !== '') {
			this.setState({
				loading: true
			});

			modelService.search(1, 10, { model_name: value })
				.then(
					json => {
						this.setState({
							modelList: json.data,
							loading: false
						});
					},
					error => {
						console.log('error');
						console.log(error);
					}
				);
		}
	}

	selectModel(e) {
		const { name, id, index } = e.target.dataset;
		const { modelList } = this.props;

		//e.preventDefault();

		this.setState({
			modelText: name,
			modelID: id,
			showList: false
		});

		this.props.filterUpdate(this.props.scope, 'model_id', id).then(this.props.applyFilters);
	}

	hoverItem(e) {
		let target = e.target,
			activeItem = (e.target.dataset.index * 1);

		this.setState({
			activeItem
		});
	}


	render() {
		const {
			showList,
			modelText,
			modelID
		} = this.state;

		const { modelList, builder } = this.props;

		let disabled = true;
		if(builder && builder.builder_id) {
			disabled = false;
		}

		return (
			<div className="sk-input-wrapper sk-text-input-wrapper" key={this.state.key}>
				<label
					className="sk-input-label"
					htmlFor="model_name"
				>Model*</label>
				<input
					type="text"
					label="Model"
					hideLabel={this.props.hideLabel}
					id="model_id"
					name="model_id"
					className={"sk-input-text " + (this.props.className ? this.props.className : '')}
					value={modelText || ''}
					placeholder="Model"
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onKeyUp={this.keyPress}
					disabled={disabled}
					required={true}
					error={this.props.error || false}
				/>

				{showList &&
					<div className="sk-autocomplete-list">
						{modelList.map((model, index) => (
							<p
								key={index}
								ref={(index === this.state.activeItem ? element => this.activeItemElement = element : '')}
								className={"sk-list-item " + (index === this.state.activeItem ? "sk-active" : '')}
								onClick={this.selectModel}
								onMouseEnter={this.hoverItem}
								data-id={model.model_id}
								data-name={model.model_name}
								data-index={index}
							>{model.model_name}</p>
						))}
					</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	let filters = {};
	if(state.paginationReducer[ownProps.scope]) {
		filters = state.paginationReducer[ownProps.scope].filters;
	}

	return {
		builder: state.builderReducer.builder,
		modelList: state.builderReducer.builder.models || [],
		filters
	};
}

const mapDispatchToProps = {
	create: modelActions.create,
	select: modelActions.select,
	filterUpdate: paginationActions.updateFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelSelect);
