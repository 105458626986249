import * as Actions from '../actions';

const initialSeakeeperState = {
	seakeepers: [],
	loading: false
};

export const seakeeperReducer = (state = initialSeakeeperState, action) => {
	switch(action.type) {
		case Actions.SEAKEEPER_GET_REQUEST:
			return {
				...state,
				loading: true
			};
		case Actions.SEAKEEPER_GET_SUCCESS:
			return {
				...state,
				loading: false,
				seakeepers: action.payload.seakeepers
			};
		default:
			return state;
	}
};
