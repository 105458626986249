import { bmyService } from '../services';
import history from '../history';

export const BMY_SEARCH_REQUEST = 'BMY_SEARCH_REQUEST';
export const BMY_SEARCH_SUCCESS = 'BMY_SEARCH_SUCCESS';
export const BMY_SEARCH_FAILURE = 'BMY_SEARCH_FAILURE';

export const BMY_GET_REQUEST = 'BMY_GET_REQUEST';
export const BMY_GET_SUCCESS = 'BMY_GET_SUCCESS';
export const BMY_GET_FAILURE = 'BMY_GET_FAILURE';

export const BMY_UPDATE_REQUEST = 'BMY_UPDATE_REQUEST';
export const BMY_UPDATE_SUCCESS = 'BMY_UPDATE_SUCCESS';
export const BMY_UPDATE_FAILURE = 'BMY_UPDATE_FAILURE';

export const BMY_CREATE_REQUEST = 'BMY_CREATE_REQUEST';
export const BMY_CREATE_SUCCESS = 'BMY_CREATE_SUCCESS';
export const BMY_CREATE_FAILURE = 'BMY_CREATE_FAILURE';

export const bmyActions = {
	get,
	search,
	update,
	create
};

function search(page = 1, perPage = 10, filters = {}) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request());

			bmyService.search(page, perPage, filters)
				.then(
					json => {
						dispatch(success(json));
						resolve(json.data);
					},
					error => {
						dispatch(failure(error));
						console.log('error');
						console.log(error);
						resolve();
					}
				);
		});
	};

	function request() {
		return {
			type: BMY_SEARCH_REQUEST
		};
	}
	function success(json) {
		return {
			type: BMY_SEARCH_SUCCESS,
			payload: {
				bmys: json.data,
				totalCount: json.total
			}
		};
	}
	function failure(error) {
		return {
			type: BMY_SEARCH_FAILURE,
			error: error
		};
	}
}

function get(bmyID) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {

			dispatch(request());

			bmyService.get(bmyID)
				.then(
					json => {
						dispatch(success(json));
						resolve(json.data);
					},
					error => {
						dispatch(failure(error));
						console.log('error');
						console.log(error);
						resolve();
					}
				);
		});
	};

	function request() {
		return {
			type: BMY_GET_REQUEST
		};
	}
	function success(json) {
		return {
			type: BMY_GET_SUCCESS,
			payload: {
				bmy: json.data
			}
		};
	}
	function failure(error) {
		return {
			type: BMY_GET_FAILURE,
			error: error
		};
	}
}

function update(bmy) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request());

			bmyService.update(bmy)
				.then(
					json => {
						dispatch(success(json));
						alert("BMY updated successfully!");
						resolve(json.data);
					},
					error => {
						dispatch(failure(error));
						alert(error.join('\n'));
						console.log(error);
						resolve();
					}
				);
		});
	};

	function request() {
		return {
			type: BMY_UPDATE_REQUEST
		};
	}
	function success(json) {
		return {
			type: BMY_UPDATE_SUCCESS,
			payload: {
				bmy: json.data,
			}
		};
	}
	function failure(error) {
		return {
			type: BMY_UPDATE_FAILURE,
			error: error
		};
	}
}

function create(bmy) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request());

			bmyService.create(bmy)
				.then(
					json => {
						dispatch(success(json));

						let bmy_id = json.data.bmy_id || false;
						if(bmy_id) {
							alert("BMY created successfully!");
							history.push('/bmy/' + bmy_id);
							resolve(json.data);
						}
					},
					error => {
						dispatch(failure(error));
						alert(error.join('\n'));
						console.log(error);
						resolve();
					}
				);
		});
	};

	function request() {
		return {
			type: BMY_CREATE_REQUEST
		};
	}
	function success(json) {
		return {
			type: BMY_CREATE_SUCCESS,
			payload: {
				bmy: json.data
			}
		};
	}
	function failure(error) {
		return {
			type: BMY_CREATE_FAILURE,
			error: error
		};
	}
}
