import React, { Component } from 'react';
import NoResults from './NoResults';

class Table extends Component {
    constructor(props) {
		super(props);

		this.state = {
			sortColumn: -1,
			sortDirection: -1
		}

		if (this.props.sortable === true) {
			this.onClick = this.sortTable.bind(this);
		} else {
			this.onClick = ()=>{return false};
		}
    }

	sortTable(e) {
		var index = e.target.dataset.index * 1,
			sortDirection = this.state.sortDirection;

		if (this.state.sortColumn === index) {
			sortDirection = sortDirection * -1;
		} else {
			sortDirection = -1
		}
		this.setState({
			sortColumn: index,
			sortDirection: sortDirection
		});
	}

    render() {
		var loadingMessage = false,
			sortableClass = this.props.sortable === true ? 'sk-sortable' : '';

        this.heading = this.props.tableHeading;
        this.body = this.props.tableBody;

        if ((this.heading.length === 0 || this.body.length === 0) && this.props.loading === false) {
            this.heading = false;
            this.body = false;
            this.noResults = <NoResults message={this.props.noResultsMessage} />;
        } else {
            this.noResults = false;
        }

		if (this.props.loading !== false) {
			loadingMessage = <NoResults message={this.props.loadingMessage} />;
			if (this.body.length === 0) {
				this.heading = false;
			}
		}

		if (this.body !== false && this.state.sortColumn > -1) {
			let sort = true,
				sortColumn = this.state.sortColumn,
				temp, one, two = '';
			while (sort) {
				sort = false;
				for (let a = 0; a < this.body.length - 1; a += 1) {
					one = this.body[a][sortColumn] || 0;
					two = this.body[a + 1][sortColumn] || 0;
					if (typeof one === 'object') {
						one = one.props.children + '';
					}
					if (typeof two === 'object') {
						two = two.props.children + '';
					}
					// check for integer compare
					if(!isNaN(one) && !isNaN(two)) {
						one = one * 1;
						two = two * 1;
					}
					if (this.state.sortDirection === 1) {
						temp = one;
						one = two;
						two = temp;
					}
					if (one > two) {
						temp = this.body[a];
						this.body[a] = this.body[a + 1];
						this.body[a + 1] = temp;
						sort = true;
					}
				}
			}
		}

        return (
            <div className={"sk-table-wrapper " + sortableClass}>
				{loadingMessage}
                {this.noResults}
                <div className="sk-table-scroll">
                    <table className="sk-table">
                        {this.heading &&
                            <thead className="sk-table-head">
                                <tr className="sk-table-heading-row">
                                    {this.heading.map((heading, index) => (
                                        <td className="sk-table-heading-cell" key={index} data-index={index} onClick={this.onClick}>
                                            <div className="sk-heading-text" data-sort={this.state.sortColumn === index && this.state.sortDirection} data-index={index}>{heading}</div>
                                        </td>
                                    ))}
                                </tr>
                            </thead>
                        }
                        {this.body &&
                            <tbody className="sk-table-body">
                                {this.body.map((row, index) => (
                                    <tr className="sk-table-row" key={index}>
                                        {row.map((cell, index) => (
                                            <td className="sk-table-cell" key={index}>{cell}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        );
    }
}

export default Table;
