import React, { Component } from 'react';
import { connect } from 'react-redux'
import { refitBoatActions } from '../actions';

class BoatSelect extends Component {
	constructor(props) {
		super(props);

		this.state = {
			boatID: false,
			boatText: "",
			showList: true,
			showListItems: [],
			boatList: [],
			loading: false,
			boatTextFormated: '',
			boatTableHeading: [
				'Select Boat',
				' '
			]
		};

		this.activeItemElement = null;
		this.listSize = (this.props.listSize * 1) || 5;

		this.onChange = this.onChange.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.selectBoat = this.selectBoat.bind(this);
		this.filterList = this.filterList.bind(this);
		this.createBoat = this.createBoat.bind(this);
		this.clearBoat = this.clearBoat.bind(this);
		this.createBoat = this.createBoat.bind(this);
	}

	componentDidMount() {
		this.setState({
			loading: true
		});

		this.props.search(1, 10, { });
	}

	componentDidUpdate(prevProps) {
		if(this.props.boat.boat_id && (this.props.boat.boat_id !== prevProps.boat.boat_id)) {
			let boat = this.props.boat;
			let builderModelText = boat.bmy.model.builder.builder_name + " " + boat.bmy.model.model_name;
			let yearText = '';
			let nameText = '';

			if(boat.bmy.boat_year && boat.bmy.boat_year !== null) {
				yearText = " " + boat.bmy.boat_year;
			}
			if(boat.boat_name) {
				nameText = " " + boat.boat_name;
			}

			let newText = builderModelText + yearText + nameText;
			let formatedText = <>{builderModelText + yearText}<em className="sk-boat-name">{nameText}</em></>;
			this.setState({
				boatText: newText,
				boatTextFormated: formatedText
			});
		}
	}

	onFocus(e) {

	}

	onBlur(e) {

	}

	onChange(e) {
		const { value } = e.target;

		this.setState({
			boatText: value,
			boatTextFormated: '',
			showList: true,
			boatID: false
		});

		this.props.select({});
	}

	filterList(e) {
		const { value } = e.target;

		if(value !== '') {
			this.setState({
				loading: true
			});

			this.props.search(1, 10, { q: value });
		}
	}

	selectBoat(e) {
		const { id, index } = e.target.dataset;
		const { boatList } = this.props;

		e.preventDefault();

		let boat = boatList[index];

		if (boat === undefined) {
			return;
		}

		let builderModelText = boat.bmy.model.builder.builder_name + " " + boat.bmy.model.model_name;
		let yearText = '';
		let nameText = '';

		if(boat.bmy.boat_year && boat.bmy.boat_year !== null) {
			yearText = " " + boat.bmy.boat_year;
		}
		if(boat.boat_name) {
			nameText = " " + boat.boat_name;
		}

		let newText = builderModelText + yearText + nameText;
		let formatedText = <>{builderModelText + yearText}<em className="sk-boat-name">{nameText}</em></>;

		this.setState({
			boatText: newText,
			boatTextFormated: formatedText,
			boatID: id,
			showList: false
		});

		this.props.select(boatList[index]);
	}

	createBoat(e) {
		e.preventDefault();

		window.location = process.env.REACT_APP_APPS_HOST + '/boats/add?src=refits';
	}

	clearBoat() {
		this.setState({
			boatText: "",
			boatTextFormated: "",
			showList: true
		});

		this.props.search(1, 10, { q: '' });
		this.props.select({});
	}

	render() {
		const {
			showList,
			boatText,
			boatID,
			boatTextFormated
		} = this.state;

		const { boatList, boat, boatListLoading } = this.props;

		return (
			<>
			<div className="sk-input-wrapper sk-text-input-wrapper" key={this.state.key}>
				<label
					className="sk-input-label"
					htmlFor="boat_name"
				>Search Boats</label>
				<p className="sk-boatname-format">{boatTextFormated}</p>
				<input
					type="text"
					label="Search Boats"
					id="boat_id"
					name="boat_id"
					className={"sk-input-text sk-boat-search-input " + (this.props.className ? this.props.className : '') + (boatTextFormated !== '' ? " sk-boat-selected" : "")}
					value={boatText || ''}
					placeholder="Search by Builder, Model, Year, or Boat Name"
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onKeyUp={this.filterList}
					disabled={this.props.disabled}
					required={true}
				/>
				{showList &&
					<div className="sk-autocomplete-list">
						{boatListLoading &&
							<div className="sk-list-loading"></div>
						}
						{boatList.map((boat, index) => (
							<p
								key={index}
								className="sk-list-item"
								onClick={this.selectBoat}
								data-id={boat.boat_id}
								data-name={boat.boat_name}
								data-index={index}
							>{boat.bmy.model.builder.builder_name} {boat.bmy.model.model_name} {boat.bmy.boat_year} <em className="sk-boat-name">{boat.boat_name}</em></p>
						))}
						{boatText !== "" && boatID === false &&
							<button className="sk-add-item" onClick={this.createBoat}>Create New Boat "<strong className="sk-boat-name">{boatText}</strong>"</button>
						}
					</div>
				}
			</div>
			<div className="sk-input-wrapper">
				{boat.boat_id &&
					<button className="sk-text-button" onClick={this.clearBoat}>Clear</button>
					}
			</div>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		boatList: state.refitBoatReducer.refitBoats || [],
		boat: state.refitBoatReducer.boat || {},
		boatListLoading: state.refitBoatReducer.loading || false
	};
}

const mapDispatchToProps = {
	search: refitBoatActions.search,
	select: refitBoatActions.select
};

export default connect(mapStateToProps, mapDispatchToProps)(BoatSelect);
