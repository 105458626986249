import { authHeader } from '../helpers';
import queryString from 'query-string';

export const sizingFormService = {
	search
};

function search(filters) {
	const query = queryString.stringify(filters);
	const requestURL = process.env.REACT_APP_API_URL + '/prediction/sizing-form?' + query;
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function handleResponse(response) {
	return response.json().then((json) => {
		if(json.success) {
			return json;
		} else {
			return Promise.reject(json.messages);
		}
	});
}
