import React from 'react';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import RefitListing from "./RefitListing";
import RefitListingWebView from "./RefitListingWebView";
import RefitSingle from "./RefitSingle";
import RefitSingleModel from "./RefitSingleModel";
import RefitSingleWebView from "./RefitSingleWebView";
import RefitAdd from "./RefitAdd";
import RefitEdit from "./RefitEdit";
import LoginPage from "./LoginPage";
import RefitListingCompare from "./RefitListingCompare";
import history from '../history';

function App() {
  return (
	<Router history={history}>
		<Switch>
			<Route path="/login" component={LoginPage} />
			<Route path="/refits-search/" exact component={RefitListing} />
			<Route path="/compare/" exact component={RefitListingCompare} />
			{
				// <Route path="/refits/add" exact component={RefitAdd} />
				// <Route path="/refits/edit/:refit_id" exact component={RefitEdit} />
				// <Route path="/refits-search/:refit_id" exact component={RefitSingle} />
			}
			<Route path="/refits/:builder_id/:model_id/" exact component={RefitSingleModel} />
			<Route path="/refit/" exact component={RefitListingWebView} />
			<Route path="/refit/:builder_model_id" exact component={RefitSingleWebView} />

			<Redirect to="/refits-search" />
		</Switch>
	</Router>
  );
}

export default App;
