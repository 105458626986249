export const PAGINATION_INIT = 'PAGINATION_INIT';
export const PAGINATION_SET_PAGINATION = 'PAGINATION_SET_PAGINATION';
export const PAGINATION_SET_PAGE = 'PAGINATION_SET_PAGE';
export const PAGINATION_SET_PER_PAGE = 'PAGINATION_SET_PER_PAGE';
export const PAGINATION_SET_FILTERS = 'PAGINATION_SET_FILTERS';
export const PAGINATION_UPDATE_FILTER = 'PAGINATION_UPDATE_FILTER';
export const PAGINATION_RESET = 'PAGINATION_RESET';

export const paginationActions = {
	init,
	setPagination,
	setPage,
	setPerPage,
	setFilters,
	updateFilter,
	reset
};

function init(scope) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: PAGINATION_INIT,
				payload: {
					scope
				}
			});

			resolve();
		})
	}
}

function setPagination(scope, page, perPage, filters = {}, hiddenFilters = {}) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: PAGINATION_SET_PAGINATION,
				payload: {
					scope,
					page,
					perPage,
					filters,
					hiddenFilters,
				}
			});

			resolve();
		})
	}
}

function setPage(scope, newPage) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: PAGINATION_SET_PAGE,
				payload: {
					scope,
					page: newPage
				}
			});

			resolve();
		})
	}
}

function setPerPage(scope, newPerPage) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: PAGINATION_SET_PER_PAGE,
				payload: {
					scope,
					perPage: newPerPage
				}
			});

			resolve();
		})
	}
}

function setFilters(scope, filters) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: PAGINATION_SET_FILTERS,
				payload: {
					scope,
					filters
				}
			});

			resolve();
		})
	}
}

function updateFilter(scope, name, value) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: PAGINATION_UPDATE_FILTER,
				payload: {
					scope,
					name,
					value
				}
			});

			resolve();
		})
	}
}

function reset(scope) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: PAGINATION_RESET,
				payload: {
					scope
				}
			});

			resolve();
		})
	}
}
