import React, { Component } from 'react';

class FileInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isFocus: false,
			files: []
		}

		this.handleChange = this.handleChange.bind(this);
		this.readMultipleFiles = this.readMultipleFiles.bind(this);
		this.doneReadingFile = this.doneReadingFile.bind(this);
	}

	handleChange(e) {
		const { files } = e.target;
		console.log(files);
		this.readMultipleFiles(files);
	}

	readMultipleFiles(files) {
		let file = null;
		let fileArray = [];
		// build initial files array with name and type
		for(let i=0; i<files.length; i++) {
			file = files[i];
			fileArray.push({ name: file.name, type: file.type, data: '' });
		}
		this.setState({
			files: fileArray
		}, () => {
			// after name and type have been saved start reading the files to store data
			for(let i=0; i<files.length; i++) {
				file = files[i];
				var reader = new FileReader();
				reader.onload = (event) => {
					let tempFiles = this.state.files;

					// store the data of the file in the proper file name
					for(let k=0; k<tempFiles.length; k++) {
						if(tempFiles[k].name === event.target.name) {
							tempFiles[k].data = event.target.result;
						}
					}

					this.setState({
						files: tempFiles
					}, () => {
						this.doneReadingFile();
					});
				}
				reader.name = file.name;
				reader.readAsDataURL(file);
			}
		});
	}

	doneReadingFile() {
		// check that all files have data before passing up to parent component
		const { files } = this.state;
		let haveAllData = true;
		for(let i=0; i < files.length; i++) {
			if(files[i].data === '') {
				haveAllData = false;
				break;
			}
		}

		if(haveAllData) {
			this.props.onChange(this.state.files);
		}
	}

	render() {
		return (
			<div className={"sk-input-wrapper sk-text-input-wrapper sk-input-type-file" + (this.props.type === 'hidden' ? " sk-hidden-input-wrapper" : '') + (this.props.required ? " sk-required" : '') + (this.props.error ? " sk-error" : "")}>
				<label
					className="sk-input-label"
					htmlFor={this.props.htmlID}
				>
					{this.props.label}
					{(this.props.required ? "*" : '')}
				</label>
				<input
					type="file"
					multiple={this.props.multiple}
					id={this.props.htmlID}
					name={this.props.htmlName}
					className={"sk-input-text " + (this.props.className ? this.props.className : '')}
					placeholder={this.props.placeholderText}
					onChange={this.handleChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onKeyDown={this.onKeyDown}
					maxLength={this.props.maxLength}
					disabled={this.props.disabled}
					required={this.props.required}
				/>
			</div>
		);
	}
}

export default FileInput;
