import React, { Component } from 'react';
import Page from '../components/Page';
import PageSection from '../components/PageSection';
import Input from '../components/Input';
import FileInput from '../components/FileInput';
import Textarea from '../components/Textarea';
import Select from '../components/Select';
import BoatSelect from '../components/BoatSelect';
import ConfigurationGroup from '../components/ConfigurationGroup';
import queryString from 'query-string';
import history from '../history';
import { connect } from 'react-redux';
import { refitBoatActions, refitActions, dealerActions, seaTrialActions, sizingFormActions } from '../actions';

class RefitAdd extends Component {
	constructor(props) {
		super(props);

		this.props.getDealers(1,1000);

		this.state = {
			boatSearchText: '',
			boat: {},
			builderList: [],
			modelList: [],
			bmyList: [],
			boat_id: '',
			dealer_id: '',
			start_date: '',
			end_date: '',
			hours: '',
			install_location: '',
			drawings: [],
			install_photos_link: '',
			customer_testimonial: '',
			sizing_form: '',
			sea_trials: [],
			config: [ { sk_model_id: '', sk_model_count: 0 } ],
			disableModel: true,
			disableYear: true,
			tableHeading: [
				'Boat Name',
				'Builder',
				'Model',
				'Year',
				<button className="sk-expand">Expand</button>
			]
		};

		this.handleChange = this.handleChange.bind(this);
		this.multiselectChange = this.multiselectChange.bind(this);
		this.configInfoChange = this.configInfoChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
	}

	componentDidMount() {
		// check for preselected boat
		let query = queryString.parse(history.location.search);
		if(query.boat_id) {
			// get boat by ID
			let { boat_id } = query;
			this.props.getBoat(boat_id);
		} else {
			this.props.selectBoat({});
		}
	}

	componentDidUpdate(prevProps) {
		if(this.props.boat.boat_id && (this.props.boat.boat_id !== prevProps.boat.boat_id)) {
			let boat = this.props.boat;
			this.props.seaTrialSearch({
				builder_name:  boat.bmy.model.builder.builder_name,
				model_name: boat.bmy.model.model_name
			});
			this.props.sizingFormSearch({ bmy: boat.bmy.bmy_id });
		}
	}

	handleChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	multiselectChange(e) {
		const { options, name } = e.target;
		let optionsCount = options.length;
		let selected = [];

		for (let i = 0; i < optionsCount; i++) {
			if (options[i].selected) {
				selected.push(options[i].value || options[i].text);
			}
		}

		this.setState({
			[name]: selected
		});
	}

	configInfoChange(newConfig) {


		this.setState({
			config: newConfig
		});
	}

	handleFileChange(files) {
		this.setState({
			drawings: files
		});
	}

	handleSubmit() {
		const {
			dealer_id,
			start_date,
			end_date,
			hours,
			install_location,
			drawings,
			install_photos_link,
			customer_testimonial,
			sizing_form,
			sea_trials,
			config
		} = this.state;

		const {
			boat
		} = this.props;

		if( !Number.isInteger(boat.boat_id * 1) || boat.boat_id === 0) {
			alert('You must select a boat.');
			console.log("Invalid boat ID.");
			return;
		}

		if( !Number.isInteger(dealer_id * 1) || dealer_id === 0) {
			alert('You must select a dealer.');
			console.log("Invalid dealer ID.");
			return;
		}

		// strip out the sk_image object from config
		let foundConfig = false;
		let clean_config = config.map((c) => {
			delete c.sk_image;
			if(c.sk_model_id !== '' && c.sk_model_count > 0) {
				foundConfig = true;
			}
			return c;
		});

		if(!foundConfig) {
			alert('You must select a Seakeeper model.');
			return;
		}

		// TODO: check required fields (use the validation object pattern?)

		// build JSON payload
		let payload = {
			boat_id: boat.boat_id,
			dealer_id,
			start_date,
			end_date,
			hours,
			install_location,
			drawings,
			install_photos_link,
			customer_testimonial,
			sizing_form,
			sea_trials,
			config: clean_config
		};

		this.props.create(payload);
	}

	render() {
		const {
			dealers,
			seaTrials,
			sizingForms
		} = this.props;

		let sectionTitle = 'Add a Refit Report',
				dealerOptions = [];

		for(let i = 0; i < dealers.length; i++) {
			dealerOptions.push({
				value: dealers[i].dealer_id,
				text: dealers[i].dealer_name
			});
		}

		let seaTrialOptions = [];
		let stBoatName = '';
		for(let i = 0; i < seaTrials.length; i++) {
			stBoatName = seaTrials[i].boat.bmy.model.builder.builder_name;
			stBoatName += " " + seaTrials[i].boat.bmy.model.model_name;
			stBoatName += " " + seaTrials[i].boat.bmy.boat_year;
			seaTrialOptions.push({
				value: seaTrials[i].trial_id,
				text: stBoatName
			});
		}

		let sizingFormOptions = [];
		let sfBoatName = '';
		for(let i = 0; i < sizingForms.length; i++) {
			sfBoatName = sizingForms[i].builder_name;
			sfBoatName += " " + sizingForms[i].model_name;
			sfBoatName += " " + sizingForms[i].boat_year;
			sizingFormOptions.push({
				value: sizingForms[i].sizing_form_id,
				text: sfBoatName
			});
		}

		return (
			<Page pageClass="rf-refits-add">
				<PageSection
					sectionTitle={sectionTitle}
					titleClassName="sk-pencil"
				>

				<div className="sk-form-wrapper">

					<h2 className="sk-form-title">Boat Information</h2>

					<div className="sk-form-row-double sk-boat-select-row">
						<BoatSelect />
					</div>
				</div>


				<div className="sk-form-wrapper">

					<h2 className="sk-form-title">Installation Details</h2>

						<div className="sk-form-row">
							<Select
								label="Installer"
								htmlName="dealer_id"
								htmlID="dealerId"
								placeholderText="a Dealer"
								options={dealerOptions}
								onChange={this.handleChange}
								value={this.state.dealer_id} />
						</div>

						<div className="sk-form-row">
							<Input
								type="date"
								label="Install Start Date"
								placeholderText="Install Start Date"
								htmlName="start_date"
								htmlID="install_start_date"
								className={(this.state.start_date !== '' ? 'sk-active' : '')}
								onChange={this.handleChange}
								value={this.state.start_date} />
						</div>

						<div className="sk-form-row">
							<Input
								type="date"
								label="Install End Date"
								placeholderText="Install End Date"
								htmlName="end_date"
								htmlID="install_end_date"
								className={(this.state.end_date !== '' ? 'sk-active' : '')}
								onChange={this.handleChange}
								value={this.state.end_date} />
						</div>

						<div className="sk-form-row">
							<Input
								label="Total Install Time"
								placeholderText="Total hours to install the Seakeeper"
								htmlName="hours"
								htmlID="installation_hours"
								onChange={this.handleChange}
								value={this.state.hours} />
						</div>

						<div className="sk-form-row">
							<Input
								label="Installation Location"
								placeholderText="Installation location inside of boat"
								htmlName="install_location"
								htmlID="installation_location"
								onChange={this.handleChange}
								value={this.state.install_location} />
						</div>

						<div className="sk-form-row">
							<Input
								label="Install Drawings"
								placeholderText="Install photos Dropbox link"
								htmlName="install_photos_link"
								htmlID="install_photos_link"
								onChange={this.handleChange}
								value={this.state.install_photos_link} />
						</div>

						<div className="sk-form-row">
							<Textarea
								label="Customer Testimonial"
								placeholderText="Customer Testimonial"
								htmlName="customer_testimonial"
								htmlID="customer_testimonial"
								onChange={this.handleChange}
								value={this.state.customer_testimonial} />
						</div>

						<h2 className="sk-form-title">Seakeeper Configuration</h2>

					</div>

					<ConfigurationGroup
						onChange={this.configInfoChange}
						configInfo={this.state.config}
					/>

					<div className="sk-form-wrapper">

						<h2 className="sk-form-title">Sea Trial Reports</h2>

						<div className="sk-form-row">
							{seaTrialOptions.length > 0 &&
								<Select
									label="Sea Trials"
									htmlName="sea_trials"
									placeholderText="Sea Trials"
									multiple
									onChange={this.multiselectChange}
									options={seaTrialOptions} />
							}
							{seaTrialOptions.length === 0 &&
								<Input
									label="Sea Trials"
									value="No Sea Trials found for this boat"
									disabled
								/>
							}
						</div>

						<h2 className="sk-form-title">Sizing Form</h2>

						<div className="sk-form-row">
							{sizingFormOptions.length > 0 &&
								<Select
									label="Sizing Form"
									htmlName="sizing_form"
									placeholderText="Sizing Form"
									onChange={this.handleChange}
									options={sizingFormOptions} />
							}
							{sizingFormOptions.length === 0 &&
								<Input
									label="Sizing Form"
									value="No Sizing Forms found for this boat"
									disabled
								/>
							}
						</div>

						<div className="sk-action-buttons">
							<button className="sk-button" type="button" onClick={this.handleSubmit}>Submit a Refit Report ›</button>
						</div>
					</div>

				</PageSection>
			</Page>
		);
	}
}

function mapStateToProps(state) {
	return {
		boat: state.refitBoatReducer.boat || {},
		refitBoats: state.refitBoatReducer.refitBoats,
		loading: state.refitBoatReducer.loading,
		dealers: state.dealerReducer.dealers,
		seaTrials: state.seaTrialReducer.seaTrials,
		sizingForms: state.sizingFormReducer.sizingForms
	};
}

const mapDispatchToProps = {
	getBoat: refitBoatActions.get,
	selectBoat: refitBoatActions.select,
	seaTrialSearch: seaTrialActions.search,
	sizingFormSearch: sizingFormActions.search,
	getDealers: dealerActions.search,
	create: refitActions.create
};

export default connect(mapStateToProps, mapDispatchToProps)(RefitAdd);
