import * as Actions from '../actions';

const initialRefitBoatState = {
	refitBoats: [],
	loading: false,
	boat: {}
};

export const refitBoatReducer = (state = initialRefitBoatState, action) => {
	switch(action.type) {
		case Actions.REFIT_BOATS_SEARCH_REQUEST:
			return {
				...state,
				loading: true
			};
		case Actions.REFIT_BOATS_SEARCH_SUCCESS:
			return {
				...state,
				loading: false,
				refitBoats: action.payload.boats,
				totalCount: action.payload.totalCount
			};
		case Actions.REFIT_BOATS_GET_REQUEST:
			return {
				...state,
				loading: true
			};
		case Actions.REFIT_BOATS_GET_SUCCESS:
			return {
				...state,
				loading: false,
				boat: action.payload.boat
			};
		case Actions.REFIT_BOATS_SELECT:
			return {
				...state,
				boat: action.payload.boat
			}
		default:
			return state;
	}
};
