import React, { Component } from 'react';
import { connect } from 'react-redux'
import { builderActions, paginationActions } from '../actions';
import { builderService } from '../services';

class BuilderSelect extends Component {
	constructor(props) {
		super(props);

		this.state = {
			builderID: false,
			builderText: "",
			showList: true,
			showListItems: [],
			builderList: [],
			loading: false,
			builderTableHeading: [
				'Select Builder',
				' '
			],
			activeItem: -1
		};

		this.activeItemElement = null;
		this.listSize = (this.props.listSize * 1) || 5;

		this.onChange = this.onChange.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.selectBuilder = this.selectBuilder.bind(this);
		this.filterList = this.filterList.bind(this);
		this.resetBuilderList = this.resetBuilderList.bind(this);
		this.keyPress = this.keyPress.bind(this);
		this.hoverItem = this.hoverItem.bind(this);
	}

	componentDidMount() {
		this.setState({
			loading: true
		});

		let search = { with_models: true, has_refits: true };

		builderService.search(1, 10, search)
			.then(
				json => {
					this.setState({
						builderList: json.data,
						loading: false
					});
				},
				error => {
					console.log('error');
					console.log(error);
				}
			);
	}

	componentDidUpdate(prevProps)  {
		if(prevProps.filters.builder_id !== this.props.filters.builder_id) {
			if(!this.props.filters.builder_id) {
				this.resetBuilderList();
				return;
			}
			let builder_id = this.props.filters.builder_id;
			builderService.get(builder_id)
				.then(
					json => {
						this.props.select(json.data);
						this.setState({
							builderID: builder_id,
							builderText: json.data.builder_name,
							builderList: [json.data],
							loading: false
						});
					},
					error => {
						console.log('error');
						console.log(error);
					}
				);
		}
	}

	resetBuilderList() {
		this.setState({
			loading: true
		});

		let search = { with_models: true, has_refits: true };

		this.props.select({});
		builderService.search(1, 10, search)
			.then(
				json => {
					this.setState({
						builderID: false,
						builderText: "",
						builderList: json.data,
						loading: false
					});
				},
				error => {
					console.log('error');
					console.log(error);
				}
			);
	}

	onFocus(e) {

	}

	onBlur(e) {

	}

	keyPress(e) {
		let { activeItem } = this.state;
		const { builderList } = this.state;
		let listSize = builderList.length;

		let key = (e.keyCode || e.which),
			down = 40,
			up = 38,
			enter = 13,
			target = e.target;

		switch (key) {
			case down:
				if (activeItem < (listSize - 1)) {
					activeItem += 1;
					this.setState({
						activeItem: activeItem
					});
				}
				e.preventDefault();
				break;

			case up:
				if (activeItem > 0) {
					activeItem -= 1;
					this.setState({
						activeItem: activeItem
					});
				}
				e.preventDefault();
				break;

			case enter:
				e.preventDefault();

				if(activeItem > -1) {
					this.selectBuilder({ target: this.activeItemElement });
				} else {
					this.props.applyFilters();
					e.target.blur();
				}
				break;

			default:
				this.filterList(e);
				break;
		}
	}

	onChange(e) {
		const { name, value } = e.target;

		this.setState({
			builderText: value,
			showList: true,
			builderID: false
		});

		this.props.select({});
	}

	filterList(e) {
		const { value } = e.target;
		const { builderList } = this.state;
		var maxListSize = 10;
		var tempList = [];

		if(value != '') {
			this.setState({
				loading: true
			});

			builderService.search(1, 10, { builder_name: value, with_models: true, has_refits: true })
				.then(
					json => {
						this.setState({
							builderList: json.data,
							loading: false
						});
					},
					error => {
						console.log('error');
						console.log(error);
					}
				);
		}
	}

	selectBuilder(e) {
		const { name, id, index } = e.target.dataset;
		const { builderList } = this.state;

		//e.preventDefault();

		this.setState({
			builderText: name,
			builderID: id,
			showList: false
		});

		this.props.select(builderList[index]);
		this.props.filterUpdate(this.props.scope, 'builder_id', id).then(this.props.applyFilters);
	}

	hoverItem(e) {
		let target = e.target,
			activeItem = (e.target.dataset.index * 1);

		this.setState({
			activeItem
		});
	}

	render() {
		const {
			showList,
			showListItems,
			builderList,
			loading,
			builderTableHeading,
			builderText,
			builderID
		} = this.state;
		const { filters } = this.props;

		return (
			<div className="sk-input-wrapper sk-text-input-wrapper" key={this.state.key}>
				<label
					className="sk-input-label"
					htmlFor="builder_name"
				>Brand*</label>
				<input
					type="text"
					label="Builder"
					hideLabel={this.props.hideLabel}
					id="builder_id"
					name="builder_id"
					className={"sk-input-text " + (this.props.className ? this.props.className : '')}
					value={builderText || ''}
					placeholder="Brand"
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onKeyUp={this.keyPress}
					disabled={this.props.disabled}
					required={true}
					error={this.props.error || false}
				/>

				{showList &&
					<div className="sk-autocomplete-list">
						{builderList.map((builder, index) => (
							<p
								key={index}
								ref={(index === this.state.activeItem ? element => this.activeItemElement = element : '')}
								className={"sk-list-item " + (index === this.state.activeItem ? "sk-active" : '')}
								onClick={this.selectBuilder}
								onMouseEnter={this.hoverItem}
								data-id={builder.builder_id}
								data-name={builder.builder_name}
								data-index={index}
							>{builder.builder_name}</p>
						))}
					</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	let filters = {};
	if(state.paginationReducer[ownProps.scope]) {
		filters = state.paginationReducer[ownProps.scope].filters;
	}

	return {
		filters
	};
}

const mapDispatchToProps = {
	create: builderActions.create,
	select: builderActions.select,
	filterUpdate: paginationActions.updateFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(BuilderSelect);
