import * as Actions from '../actions';

const initialSizingFormState = {
	sizingForms: [],
	loading: false
};

export const sizingFormReducer = (state = initialSizingFormState, action) => {
	switch(action.type) {
		case Actions.SIZING_FORM_SEARCH_REQUEST:
			return {
				...state,
				loading: true
			};
		case Actions.SIZING_FORM_SEARCH_SUCCESS:
			return {
				...state,
				loading: false,
				sizingForms: action.payload.sizingForms
			};
		default:
			return state;
	}
};
