import { refitBoatService } from '../services';

export const REFIT_BOATS_SEARCH_REQUEST = 'REFIT_BOATS_SEARCH_REQUEST';
export const REFIT_BOATS_SEARCH_SUCCESS = 'REFIT_BOATS_SEARCH_SUCCESS';
export const REFIT_BOATS_SEARCH_FAILURE = 'REFIT_BOATS_SEARCH_FAILURE';

export const REFIT_BOATS_ADD_REQUEST = 'REFIT_BOATS_ADD_REQUEST';
export const REFIT_BOATS_ADD_SUCCESS = 'REFIT_BOATS_ADD_SUCCESS';
export const REFIT_BOATS_ADD_FAILURE = 'REFIT_BOATS_ADD_FAILURE';

export const REFIT_BOATS_GET_REQUEST = 'REFIT_BOATS_GET_REQUEST';
export const REFIT_BOATS_GET_SUCCESS = 'REFIT_BOATS_GET_SUCCESS';
export const REFIT_BOATS_GET_FAILURE = 'REFIT_BOATS_GET_FAILURE';

export const REFIT_BOATS_UPDATE_REQUEST = 'REFIT_BOATS_UPDATE_REQUEST';
export const REFIT_BOATS_UPDATE_SUCCESS = 'REFIT_BOATS_UPDATE_SUCCESS';
export const REFIT_BOATS_UPDATE_FAILURE = 'REFIT_BOATS_UPDATE_FAILURE';

export const REFIT_BOATS_SELECT = 'REFIT_BOATS_SELECT';

export const refitBoatActions = {
	search,
	get,
	select
};

function search(page = 1, perPage = 10, filters = {}) {
	return (dispatch) => {
		dispatch(request());

		refitBoatService.search(page, perPage, filters)
			.then(
				json => {
					dispatch(success(json));
				},
				error => {
					dispatch(failure(error));
					alert(error.join('\n'));
					console.log(error);
				}
			);
	};

	function request() {
		return {
			type: REFIT_BOATS_SEARCH_REQUEST
		};
	}
	function success(json) {
		return {
			type: REFIT_BOATS_SEARCH_SUCCESS,
			payload: {
				boats: json.data,
				totalCount: json.total
			}
		};
	}
	function failure(error) {
		return {
			type: REFIT_BOATS_SEARCH_FAILURE,
			error: error
		};
	}
}

function get(boatID) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request());

			refitBoatService.get(boatID)
				.then(
					json => {
						dispatch(success(json));
						resolve(json.data);
					},
					error => {
						dispatch(failure(error));
						console.log(error);
						resolve();
					}
				);
		});
	};

	function request() {
		return {
			type: REFIT_BOATS_GET_REQUEST
		};
	}
	function success(json) {
		return {
			type: REFIT_BOATS_GET_SUCCESS,
			payload: {
				boat: json.data,
			}
		};
	}
	function failure(error) {
		return {
			type: REFIT_BOATS_GET_FAILURE,
			error: error
		};
	}
}

function select(boat) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: REFIT_BOATS_SELECT,
				payload: {
					boat
				}
			});

			resolve(boat);
		})
	}
}
