import * as Actions from '../actions';

const initialBuilderState = {
	builders: [],
	loading: false,
	builder: {},
	lastUpdated: Date.now() / 1000 | 0
};

export const builderReducer = (state = initialBuilderState, action) => {
	switch(action.type) {
		case Actions.BUILDER_SEARCH_REQUEST:
			return {
				...state,
				loading: true
			};
		case Actions.BUILDER_SEARCH_SUCCESS:
			return {
				...state,
				loading: false,
				builders: action.payload.builders,
				lastUpdated: Date.now() / 1000 | 0,
				totalCount: action.payload.totalCount
			};

		case Actions.BUILDER_GET_REQUEST:
			return {
				...state,
				loading: true,
				builder: {}
			};
		case Actions.BUILDER_GET_SUCCESS:
			return {
				...state,
				loading: false,
				builder: action.payload.builder,
				lastUpdated: Date.now() / 1000 | 0
			};
		case Actions.BUILDER_GET_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			}

		case Actions.BUILDER_UPDATE_REQUEST:
			return {
				...state,
				loading: true,
				builder: {}
			};
		case Actions.BUILDER_UPDATE_SUCCESS:
			return {
				...state,
				loading: false,
				builder: action.payload.builder,
				lastUpdated: Date.now() / 1000 | 0
			};
		case Actions.BUILDER_UPDATE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			}

		case Actions.BUILDER_CREATE_REQUEST:
			return {
				...state,
				loading: true,
				builder: {}
			};
		case Actions.BUILDER_CREATE_SUCCESS:
			return {
				...state,
				loading: false,
				builder: action.payload.builder,
				lastUpdated: Date.now() / 1000 | 0
			};
		case Actions.BUILDER_CREATE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			}

		case Actions.BUILDER_SELECT:
			return {
				...state,
				builder: action.payload.builder
			}
		default:
			return state;
	}
};
