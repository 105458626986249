import * as Actions from '../actions';

const initialApplicationState = {
	applications: [],
	subApplications: [],
	loading: false
};

export const applicationReducer = (state = initialApplicationState, action) => {
	switch(action.type) {
		case Actions.APPLICATION_GET_REQUEST:
			return {
				...state,
				loading: true
			};
		case Actions.APPLICATION_GET_SUCCESS:
			return {
				...state,
				loading: false,
				applications: action.payload.applications
			};
		default:
			return state;
	}
};
