import { modelService } from '../services';

export const MODEL_SEARCH_REQUEST = 'MODEL_SEARCH_REQUEST';
export const MODEL_SEARCH_SUCCESS = 'MODEL_SEARCH_SUCCESS';
export const MODEL_SEARCH_FAILURE = 'MODEL_SEARCH_FAILURE';

export const MODEL_SEARCHPP_REQUEST = 'MODEL_SEARCHPP_REQUEST';
export const MODEL_SEARCHPP_SUCCESS = 'MODEL_SEARCHPP_SUCCESS';
export const MODEL_SEARCHPP_FAILURE = 'MODEL_SEARCHPP_FAILURE';

export const MODEL_UPDATE_REQUEST = 'MODEL_UPDATE_REQUEST';
export const MODEL_UPDATE_SUCCESS = 'MODEL_UPDATE_SUCCESS';
export const MODEL_UPDATE_FAILURE = 'MODEL_UPDATE_FAILURE';

export const MODEL_CREATE_REQUEST = 'MODEL_CREATE_REQUEST';
export const MODEL_CREATE_SUCCESS = 'MODEL_CREATE_SUCCESS';
export const MODEL_CREATE_FAILURE = 'MODEL_CREATE_FAILURE';

export const MODEL_GET_REQUEST = 'MODEL_GET_REQUEST';
export const MODEL_GET_SUCCESS = 'MODEL_GET_SUCCESS';
export const MODEL_GET_FAILURE = 'MODEL_GET_FAILURE';

export const modelActions = {
	search,
	searchPP,
	update,
	create,
	get
};

function search(page = 1, perPage = 10, filters = {}) {
	return (dispatch) => {
		dispatch(request());

		modelService.search(page, perPage, filters)
			.then(
				json => {
					dispatch(success(json));
				},
				error => {
					dispatch(failure(error));
					if(Array.isArray(error)) {
						alert(error.join('\n'));
					} else {
						alert(error);
					}
					console.log(error);
				}
			);
	};

	function request() {
		return {
			type: MODEL_SEARCH_REQUEST
		};
	}
	function success(json) {
		return {
			type: MODEL_SEARCH_SUCCESS,
			payload: {
				models: json.data,
				totalCount: json.total
			}
		};
	}
	function failure(error) {
		return {
			type: MODEL_SEARCH_FAILURE,
			error: error
		};
	}
}

function searchPP(page = 1, perPage = 10, filters = {}) {
	return (dispatch) => {
		dispatch(request());

		modelService.searchPP(page, perPage, filters)
			.then(
				json => {
					dispatch(success(json));
				},
				error => {
					dispatch(failure(error));
					if(Array.isArray(error)) {
						alert(error.join('\n'));
					} else {
						alert(error);
					}
					console.log(error);
				}
			);
	};

	function request() {
		return {
			type: MODEL_SEARCHPP_REQUEST
		};
	}
	function success(json) {
		return {
			type: MODEL_SEARCHPP_SUCCESS,
			payload: {
				modelsPP: json.models
			}
		};
	}
	function failure(error) {
		return {
			type: MODEL_SEARCHPP_FAILURE,
			error: error
		};
	}
}

function update(model) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request());

			modelService.update(model)
				.then(
					json => {
						dispatch(success(json));
						alert("Model updated successfully!");
						resolve(json.data);
					},
					error => {
						dispatch(failure(error));
						alert(error.join('\n'));
						console.log(error);
						resolve();
					}
				);
		});
	};

	function request() {
		return {
			type: MODEL_UPDATE_REQUEST
		};
	}
	function success(json) {
		return {
			type: MODEL_UPDATE_SUCCESS,
			payload: {
				model: json.data,
			}
		};
	}
	function failure(error) {
		return {
			type: MODEL_UPDATE_FAILURE,
			error: error
		};
	}
}

function create(model) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request());

			modelService.create(model)
				.then(
					json => {
						dispatch(success(json));
						alert("Model created successfully!");
						resolve(json.data);
					},
					error => {
						dispatch(failure(error));
						alert(error.join('\n'));
						console.log(error);
						resolve();
					}
				);
		});
	};

	function request() {
		return {
			type: MODEL_CREATE_REQUEST
		};
	}
	function success(json) {
		return {
			type: MODEL_CREATE_SUCCESS,
			payload: {
				model: json.data,
			}
		};
	}
	function failure(error) {
		return {
			type: MODEL_CREATE_FAILURE,
			error: error
		};
	}
}

function get(modelID, builderID) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {

			dispatch(request());

			modelService.get(modelID, builderID)
				.then(
					json => {
						dispatch(success(json));
						resolve(json.data);
					},
					error => {
						dispatch(failure(error));
						console.log('error');
						console.log(error);
						resolve();
					}
				);
		});
	};

	function request() {
		return {
			type: MODEL_GET_REQUEST
		};
	}
	function success(json) {
		return {
			type: MODEL_GET_SUCCESS,
			payload: {
				model: json.data
			}
		};
	}
	function failure(error) {
		return {
			type: MODEL_GET_FAILURE,
			error: error
		};
	}
}
