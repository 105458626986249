import * as Actions from '../actions';

const initialModelState = {
	models: [],
	loading: false,
	model: {},
	lastUpdated: Date.now() / 1000 | 0
};

export const modelReducer = (state = initialModelState, action) => {
	switch(action.type) {
		case Actions.MODEL_SEARCH_REQUEST:
			return {
				...state,
				loading: true
			};
		case Actions.MODEL_SEARCH_SUCCESS:
			return {
				...state,
				loading: false,
				models: action.payload.models,
				totalCount: action.payload.totalCount,
				lastUpdated: Date.now() / 1000 | 0
			};

		case Actions.MODEL_SEARCHPP_REQUEST:
			return {
				...state
			};
		case Actions.MODEL_SEARCHPP_SUCCESS:
			return {
				...state,
				modelsPP: action.payload.modelsPP
				};

		case Actions.MODEL_UPDATE_REQUEST:
			return {
				...state,
				loading: true
			};
		case Actions.MODEL_UPDATE_SUCCESS:
			return {
				...state,
				loading: false,
				model: action.payload.model,
				lastUpdated: Date.now() / 1000 | 0
			};

		case Actions.MODEL_CREATE_REQUEST:
			return {
				...state,
				loading: true
			};
		case Actions.MODEL_CREATE_SUCCESS:
			return {
				...state,
				loading: false,
				model: action.payload.model,
				lastUpdated: Date.now() / 1000 | 0
			};

		case Actions.MODEL_GET_REQUEST:
			return {
				...state,
				loading: true,
				model: {}
			};
		case Actions.MODEL_GET_SUCCESS:
			return {
				...state,
				loading: false,
				model: action.payload.model,
				lastUpdated: Date.now() / 1000 | 0
			};
		case Actions.MODEL_GET_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			}

		default:
			return state;
	}
};
