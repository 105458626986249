import { builderService } from '../services';
import history from '../history';

export const BUILDER_SEARCH_REQUEST = 'BUILDER_SEARCH_REQUEST';
export const BUILDER_SEARCH_SUCCESS = 'BUILDER_SEARCH_SUCCESS';
export const BUILDER_SEARCH_FAILURE = 'BUILDER_SEARCH_FAILURE';

export const BUILDER_GET_REQUEST = 'BUILDER_GET_REQUEST';
export const BUILDER_GET_SUCCESS = 'BUILDER_GET_SUCCESS';
export const BUILDER_GET_FAILURE = 'BUILDER_GET_FAILURE';

export const BUILDER_UPDATE_REQUEST = 'BUILDER_UPDATE_REQUEST';
export const BUILDER_UPDATE_SUCCESS = 'BUILDER_UPDATE_SUCCESS';
export const BUILDER_UPDATE_FAILURE = 'BUILDER_UPDATE_FAILURE';

export const BUILDER_CREATE_REQUEST = 'BUILDER_CREATE_REQUEST';
export const BUILDER_CREATE_SUCCESS = 'BUILDER_CREATE_SUCCESS';
export const BUILDER_CREATE_FAILURE = 'BUILDER_CREATE_FAILURE';

export const BUILDER_SELECT = 'BUILDER_SELECT';

export const builderActions = {
	get,
	search,
	select,
	update,
	create
};

function search(page = 1, perPage = 10, filters = {}) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request());

			builderService.search(page, perPage, filters)
				.then(
					json => {
						dispatch(success(json));
						resolve(json.data);
					},
					error => {
						dispatch(failure(error));
						console.log('error');
						console.log(error);
						resolve();
					}
				);
		});
	};

	function request() {
		return {
			type: BUILDER_SEARCH_REQUEST
		};
	}
	function success(json) {
		return {
			type: BUILDER_SEARCH_SUCCESS,
			payload: {
				builders: json.data,
				totalCount: json.total
			}
		};
	}
	function failure(error) {
		return {
			type: BUILDER_SEARCH_FAILURE,
			error: error
		};
	}
}

function get(builderID) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {

			dispatch(request());

			builderService.get(builderID)
				.then(
					json => {
						dispatch(success(json));
						resolve(json.data);
					},
					error => {
						dispatch(failure(error));
						console.log('error');
						console.log(error);
						resolve();
					}
				);
		});
	};

	function request() {
		return {
			type: BUILDER_GET_REQUEST
		};
	}
	function success(json) {
		return {
			type: BUILDER_GET_SUCCESS,
			payload: {
				builder: json.data
			}
		};
	}
	function failure(error) {
		return {
			type: BUILDER_GET_FAILURE,
			error: error
		};
	}
}

function select(builder) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: BUILDER_SELECT,
				payload: {
					builder
				}
			});

			resolve(builder);
		})
	}
}

function update(builder) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request());

			builderService.update(builder)
				.then(
					json => {
						dispatch(success(json));
						alert("Builder updated successfully!");
						resolve(json.data);
					},
					error => {
						dispatch(failure(error));
						alert(error.join('\n'));
						console.log(error);
						resolve();
					}
				);
		});
	};

	function request() {
		return {
			type: BUILDER_UPDATE_REQUEST
		};
	}
	function success(json) {
		return {
			type: BUILDER_UPDATE_SUCCESS,
			payload: {
				builder: json.data,
			}
		};
	}
	function failure(error) {
		return {
			type: BUILDER_UPDATE_FAILURE,
			error: error
		};
	}
}

function create(builder) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request());

			builderService.create(builder)
				.then(
					json => {
						dispatch(success(json));

						let builder_id = json.data.builder_id || false;
						if(builder_id) {
							alert("Builder created successfully!");
							history.push('/builders/' + builder_id);
							resolve(json.data);
						}
					},
					error => {
						dispatch(failure(error));
						alert(error.join('\n'));
						console.log(error);
						resolve();
					}
				);
		});
	};

	function request() {
		return {
			type: BUILDER_CREATE_REQUEST
		};
	}
	function success(json) {
		return {
			type: BUILDER_CREATE_SUCCESS,
			payload: {
				builder: json.data
			}
		};
	}
	function failure(error) {
		return {
			type: BUILDER_CREATE_FAILURE,
			error: error
		};
	}
}
