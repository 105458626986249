import * as Actions from '../actions';

const initialBMYState = {
	bmys: [],
	loading: false,
	bmy: {},
	lastUpdated: 0
};

export const bmyReducer = (state = initialBMYState, action) => {
	switch(action.type) {
		case Actions.BMY_SEARCH_REQUEST:
			return {
				...state,
				loading: true
			};
		case Actions.BMY_SEARCH_SUCCESS:
			return {
				...state,
				loading: false,
				bmys: action.payload.bmys,
				lastUpdated: Date.now() / 1000 | 0,
				totalCount: action.payload.totalCount
			};

		case Actions.BMY_GET_REQUEST:
			return {
				...state,
				loading: true,
				bmy: {}
			};
		case Actions.BMY_GET_SUCCESS:
			return {
				...state,
				loading: false,
				bmy: action.payload.bmy,
				lastUpdated: Date.now() / 1000 | 0
			};
		case Actions.BMY_GET_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			}

		case Actions.BMY_UPDATE_REQUEST:
			return {
				...state,
				loading: true,
				bmy: {}
			};
		case Actions.BMY_UPDATE_SUCCESS:
			return {
				...state,
				loading: false,
				bmy: action.payload.bmy,
				lastUpdated: Date.now() / 1000 | 0
			};
		case Actions.BMY_UPDATE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			}

		case Actions.BMY_CREATE_REQUEST:
			return {
				...state,
				loading: true,
				bmy: {}
			};
		case Actions.BMY_CREATE_SUCCESS:
			return {
				...state,
				loading: false,
				bmy: action.payload.bmy,
				lastUpdated: Date.now() / 1000 | 0
			};
		case Actions.BMY_CREATE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state;
	}
};
