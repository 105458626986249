import { sizingFormService } from '../services';

export const SIZING_FORM_SEARCH_REQUEST = 'SIZING_FORM_SEARCH_REQUEST';
export const SIZING_FORM_SEARCH_SUCCESS = 'SIZING_FORM_SEARCH_SUCCESS';
export const SIZING_FORM_SEARCH_FAILURE = 'SIZING_FORM_SEARCH_FAILURE';

export const sizingFormActions = {
	search
};

function search(filters = {}) {
	return (dispatch) => {
		dispatch(request());

		sizingFormService.search(filters)
			.then(
				json => {
					dispatch(success(json));
				},
				error => {
					dispatch(failure(error));
					//alert(error.join('\n'));
					console.log(error);
				}
			);
	};

	function request() {
		return {
			type: SIZING_FORM_SEARCH_REQUEST
		};
	}
	function success(json) {
		return {
			type: SIZING_FORM_SEARCH_SUCCESS,
			payload: {
				sizingForms: json.data
			}
		};
	}
	function failure(error) {
		return {
			type: SIZING_FORM_SEARCH_FAILURE,
			error: error
		};
	}
}
