import React, { Component } from 'react';

class Textarea extends Component {
    render() {
        return (
            <div className={"sk-input-wrapper " + (this.props.required ? "sk-required" : '')}>
                <label
                    className="sk-input-label"
                    htmlFor={this.props.htmlID}
                >
                    {this.props.label}
                    {(this.props.required ? "*" : '')}
                </label>
                <textarea
                  type={this.props.type}
                  id={this.props.htmlID}
                  name={this.props.htmlName}
                  className={"sk-input-textarea " + (this.props.className ? this.props.className : '')}
                  defaultValue={this.props.defaultValue}
                  placeholder={this.props.placeholderText}
                  onChange={this.props.onChange}
                  maxLength={this.props.maxLength}
                  disabled={this.props.disabled}
                  required={this.props.required}
                  value={this.props.value}
                ></textarea>
            </div>
        );
    }
}

export default Textarea;
