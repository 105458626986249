import React, { Component } from 'react';
import Select from './Select';
import { connect } from 'react-redux';
import history from '../history';
import queryString from 'query-string';
import { paginationActions } from '../actions';

class Pagination extends Component {
	constructor(props) {
		super(props);

		this.state = {
			perPageValues: [
				{ value: 5, text: '5 per page' },
				{ value: 10, text: '10 per page' },
				{ value: 25, text: '25 per page' },
				{ value: 50, text: '50 per page' },
				{ value: 100, text: '100 per page' },
			],
			pageSpread: 4
		};

		this.perPageChange = this.perPageChange.bind(this);
		this.nextClick = this.nextClick.bind(this);
		this.prevClick = this.prevClick.bind(this);
		this.clickPage = this.clickPage.bind(this);
		this.paginationUpdated = this.paginationUpdated.bind(this);
	}

	perPageChange(e) {
		let newPerPage = e.target.value || 5;

		this.props.setPerPage(this.props.scope, newPerPage)
		.then(() => {
			this.paginationUpdated();
		});
	}

	nextClick(e) {
		let newPage = (this.props.page * 1) + 1;

		this.props.setPage(this.props.scope, newPage)
		.then(() => {
			this.paginationUpdated();
		});
	}

	prevClick(e) {
		let newPage = (this.props.page * 1) - 1;

		this.props.setPage(this.props.scope, newPage)
		.then(() => {
			this.paginationUpdated();
		});
	}

	clickPage(e) {
		let newPage = e.target.dataset.page || 1;

		this.props.setPage(this.props.scope, newPage)
		.then(() => {
			this.paginationUpdated();
		});
	}

	paginationUpdated() {
		const { page, perPage, filters, hiddenFilters } = this.props;
		this.props.fetchAction(page, perPage, {...filters, ...hiddenFilters});
		let url = '?page=' + page + '&perPage=' + perPage;
		if(Object.entries(filters).length > 0) {
			url += '&' + queryString.stringify(filters);
		}
		history.push({
			search: url
		});
	}

	render() {

		const { perPageValues } = this.state;
		const { totalResults, page, perPage } = this.props;
		let currentPage = page * 1,
			totalPages = Math.ceil(totalResults / perPage),
			nextPage = currentPage + 1,
			prevPage = currentPage - 1,
			prevClass = (prevPage > 0) ? "sk-active"  : "",
			nextClass = (nextPage <= totalPages) ? "sk-active"  : "",
			rangeStart = (perPage * currentPage) - perPage + 1,
			rangeEnd = (perPage * currentPage > totalResults) ? totalResults : perPage * currentPage,
			pages = [],
			maxPagers = 9;

		console.log('total pages', totalPages);
		// Pager display logic

		// If there are less than or equal total pages to the max number of pagers allowed
		// <- 1 2 3 4 ->
		if (totalPages <= maxPagers) {
			for (let a = 1; a <= totalPages; a += 1) {
				if (a === currentPage) {
					pages.push( <button type="button" onClick={this.clickPage} data-page={a} key={a} className="sk-page sk-active">{a}</button> );
				} else {
					pages.push( <button type="button" onClick={this.clickPage} data-page={a} key={a} className="sk-page">{a}</button> );
				}
			}
		}

		// If there are more pages than the max nubmer of pagers allowed
		if (totalPages > maxPagers) {

			// If the current page is with in 3 from the start or end
			// <- 1 2 3 4 ... 9 10 11 12 ->
			if (currentPage <= 3 || currentPage >= totalPages - 2) {
				for (let a = 1; a <= 4; a += 1) {
					if (a === currentPage) {
						pages.push( <button type="button" onClick={this.clickPage} data-page={a} className="sk-page sk-active">{a}</button> );
					} else {
						pages.push( <button type="button" onClick={this.clickPage} data-page={a} className="sk-page">{a}</button> );
					}
				}
				pages.push( <button type="button" name="blank" className="sk-page sk-blank">...</button> );
				for (let a = totalPages - 3; a <= totalPages; a += 1) {
					if (a === currentPage) {
						pages.push( <button type="button" onClick={this.clickPage} data-page={a} className="sk-page sk-active">{a}</button> );
					} else {
						pages.push( <button type="button" onClick={this.clickPage} data-page={a} className="sk-page">{a}</button> );
					}
				}
			} else {

				// If the curent page is after the first 3 or before the last 3
				// <- 1 ... 5 6 7 8 9 ... 12 ->
				pages.push( <button type="button" onClick={this.clickPage} data-page={1} className="sk-page">{1}</button> );
				pages.push( <button type="button" name="blank" className="sk-page sk-blank">...</button> );
				for (let a = currentPage - 2; a <= currentPage + 2; a += 1) {
					if (a === currentPage) {
						pages.push( <button type="button" onClick={this.clickPage} data-page={a} className="sk-page sk-active">{a}</button> );
					} else {
						pages.push( <button type="button" onClick={this.clickPage} data-page={a} className="sk-page">{a}</button> );
					}
				}
				pages.push( <button type="button" name="blank" className="sk-page sk-blank">...</button> );
				pages.push( <button type="button" onClick={this.clickPage} data-page={totalPages} className="sk-page">{totalPages}</button> );
			}
		}

		return (
			<>
			{totalResults > 0 &&
				<div className="sk-pagination">
					<div className="sk-pagers">
						<div className="sk-pages-wrapper">
							<button type="button" onClick={this.prevClick} className={"sk-pagination-prev " + prevClass}>
								&#10229;
								<span className="sk-pagination-text">Prev</span>
							</button>
							{pages}
							<button type="button" onClick={this.nextClick} className={"sk-pagination-next " + nextClass}>
								<span className="sk-pagination-text">Next</span>
								&#10230;
							</button>
						</div>
					</div>

					<div className="sk-limit">
						<Select
							options={perPageValues}
							onChange={this.perPageChange}
							value={perPage}
						/>
						<p className="sk-results-count">{rangeStart} - {rangeEnd} of {totalResults}</p>
					</div>
				</div>
			}
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	let page = 1;
	let perPage = 10;
	let filters = {};
	let hiddenFilters = {};
	if(state.paginationReducer[ownProps.scope]) {
		page = state.paginationReducer[ownProps.scope].page;
		perPage = state.paginationReducer[ownProps.scope].perPage;
		filters = state.paginationReducer[ownProps.scope].filters;
		hiddenFilters = state.paginationReducer[ownProps.scope].hiddenFilters;
	}

	return {
		totalResults: state[ownProps.reducerName].totalCount,
		page,
		perPage,
		filters,
		hiddenFilters
	};
}

const mapDispatchToProps = {
	setPage: paginationActions.setPage,
	setPerPage: paginationActions.setPerPage
};

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
