import React, { Component } from 'react';

import Header from './Header';

class Page extends Component {
    render() {
        return (
            <div className={"sk-page " + (this.props.pageClass ? this.props.pageClass : '')}>
                <Header />
                <div className="sk-page-content">
                    { this.props.children }
                </div>
            </div>
        );
    }
}

export default Page;
