import React, { Component } from 'react';

class Select extends Component {
	render() {

		return (
			<div className={"sk-input-wrapper sk-select-wrapper " + (this.props.required ? "sk-required" : '') +
			(this.props.error ? " sk-error" : "")}>
				<label
					className="sk-input-label"
					htmlFor={this.props.htmlID}
				>
					{this.props.label}
					{(this.props.required ? "*" : '')}
				</label>
				<select
					id={this.props.htmlID}
					name={this.props.htmlName}
					onChange={this.props.onChange}
					className="sk-select"
					disabled={this.props.disabled}
					multiple={this.props.multiple}
					value={this.props.value}>
						<option value="">Select {this.props.placeholderText}</option>
						{this.props.options &&
							this.props.options.map(option => (
								<option value={option.value} key={option.value}>{option.text}</option>
							))
						}
				</select>
			</div>
		);
	}
}

export default Select;
