import React, { Component } from 'react';
import { connect } from 'react-redux'
import { applicationActions, seakeeperActions, paginationActions, builderActions, modelActions } from '../actions';
import { builderService } from '../services';

class FilterMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedApplication: "1",
			hideBuilders: true,
			builderList: []
		}

		this.selectApplication = this.selectApplication.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterBuilders = this.filterBuilders.bind(this);
		this.selectBuilder = this.selectBuilder.bind(this);
	}

	componentDidMount() {
		this.props.getApplications();
		this.props.getSeakeepers();
		this.props.getBuilders();
	}

	handleChange(e) {
		const { name, value } = e.target;
		this.props.filterUpdate(this.props.scope, name, value);
	}

	selectApplication(event) {
		var applicationID = event.target.value * 1;

		this.setState({
			selectedApplication: applicationID
		});
		// call the base event handler as well
		this.handleChange(event);
	}

	filterBuilders(e) {
		const { value } = e.target;
		const { builderList } = this.state;
		var maxListSize = 10;
		var tempList = [];

		if(value != '') {
			this.setState({
				loading: true
			});

			builderService.search(1, 10, { builder_name: value, with_models: true })
				.then(
					json => {
						this.setState({
							builderList: json.data,
							loading: false,
							hideBuilders: false
						});
					},
					error => {
						console.log('error');
						console.log(error);
					}
				);
		}
	}

	selectBuilder(event) {
		var builder = event.target.textContent,
			builder_id = event.target.getAttribute('data-id');

		this.props.filterUpdate(this.props.scope, "builder_name", builder);

		this.setState({
			hideBuilders: true
		});

		this.props.getModels(builder_id);
	}

	render() {
		const { applications, seakeepers, filters, dealers, models } = this.props;

		var subapps = [];
		// for (let a = 0; a < subApplications.length; a += 1) {
		// 	if (subApplications[a].boat_app_id == this.state.selectedApplication) {
		// 		subapps.push(subApplications[a]);
		// 	}
		// }
		if(applications && applications.length > 0) {
			applications.forEach((app) => {
				if(app.boat_app_id === this.state.selectedApplication) {
					app.sub_applications.forEach((subApp) => {
						subapps.push(subApp);
					});
				}
			});
		}

		let radiusList = ["25", "50", "100", "200", "300", "400", "500"];

		return (
			<div className={"sk-filter-menu" + (this.props.show ? ' show' : '')}>
				<div className="sk-filter-width">
					<div className="sk-filter-group">
						<p className="sk-filter-name">Boat</p>
						<input
							type="text"
							className="sk-input"
							placeholder="Builder"
							onKeyUp={this.filterBuilders}
							onChange={this.handleChange}
							name="builder_name"
							value={filters.builder_name || ''}
							autofill="off"
							autoComplete="off"
						/>
						{this.state.builderList.length > 0 && this.state.hideBuilders === false &&
							<div className="sk-autocomplete-list">
								{this.state.builderList.map((builder, index) => (
									<p
										key={index}
										className="sk-list-item"
										onClick={this.selectBuilder}
										data-id={builder.builder_id}
									>{builder.builder_name}</p>
								))}
							</div>
						}

						<select
							className="sk-select"
							onChange={this.handleChange}
							name="model_name"
							value={filters.model_name || ''}
						>
							<option value="">Model</option>
							{models.map((model, index) => (
								<option
									key={index}
								>{model.model_name}</option>
							))}
						</select>

						<input
							type="text"
							className="sk-input"
							placeholder="Year"
							onChange={this.handleChange}
							name="year"
							value={filters.year || ''}
						/>
					</div>

					<div className="sk-filter-group">
						<p className="sk-filter-name">Boat Type</p>
						<select className="sk-select" onChange={this.selectApplication} name="boat_application" value={filters.boat_application || ''}>
							<option value="">Boat Type</option>
							{applications.map((application, index) => (
								<option
									key={index}
									value={application.boat_app_id}
								>{application.boat_application_name}</option>
							))}
						</select>
						<select className="sk-select" onChange={this.handleChange} name="boat_sub_application" value={filters.boat_sub_application || ''}>
							<option value="">Sub-Type</option>
							{subapps.map((subApplication, index) => (
								<option
									key={index}
									value={subApplication.sub_application_id}
								>{subApplication.sub_application_name}</option>
							))}
						</select>
					</div>

					<div className="sk-filter-group">
						<p className="sk-filter-name">Boat Size Range</p>
						<div className="sk-range">
							<input
								type="text"
								className="sk-input"
								placeholder="Min"
								onChange={this.handleChange}
								name="length_min"
								value={filters.length_min || ''}
							/>
							<span className="sk-dash">-</span>
							<input
								type="text"
								className="sk-input"
								placeholder="Max"
								onChange={this.handleChange}
								name="length_max"
								value={filters.length_max || ''}
							/>
						</div>
					</div>

					<div className="sk-filter-group">
						<p className="sk-filter-name">Seakeeper Model</p>
						<select className="sk-select" onChange={this.handleChange} name="seakeeper" value={filters.seakeeper || ''}>
							<option value="">Model</option>
							{seakeepers.map((seakeeper, index) => (
								<option
									key={index}
									value={seakeeper.seakeeper_id}
									>{seakeeper.seakeeper_model}</option>
							))}
						</select>
					</div>

					<div className="sk-filter-group">
						<p className="sk-filter-name">Install Location</p>
						<input
							type="text"
							className="sk-input"
							placeholder="Install Location"
							onChange={this.handleChange}
							name="install_location"
							value={filters.install_location || ''}
						/>
					</div>

					<div className="sk-filter-group">
						<p className="sk-filter-name">Dealer Location</p>
						<select className="sk-select" onChange={this.handleChange} name="dealer_radius" value={filters.dealer_radius || ''}>
							<option value="">Radius</option>
								{radiusList.map((radius, index) => (
									<option
										key={index}
										value={radius}
									>{radius}</option>
								))}
						</select>
						<input
							type="text"
							className="sk-input"
							placeholder="Zip"
							onChange={this.handleChange}
							name="dealer_location"
							value={filters.dealer_location || ''}
						/>
					</div>

					<div className="sk-filter-group">
						<p className="sk-filter-name">Dealer</p>
						<select className="sk-select" onChange={this.handleChange} name="dealer" value={filters.dealer || ''}>
							<option>Dealer Name</option>
							{dealers.map((dealer, index) => (
								<option
									key={index}
									value={dealer.dealer_id}
								>{dealer.dealer_name}</option>
							))}
						</select>
					</div>


					<div className="sk-filter-group">
						<p className="sk-filter-name">Installation Hour Range</p>
						<div className="sk-range">
							<input
								type="text"
								className="sk-input"
								placeholder="Min"
								onChange={this.handleChange}
								name="hours_min"
								value={filters.hours_min || ''}
							/>
							<span className="sk-dash">-</span>
							<input
								type="text"
								className="sk-input"
								placeholder="Max"
								onChange={this.handleChange}
								name="hours_max"
								value={filters.hours_max || ''}
							/>
						</div>
					</div>

				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	let filters = {};
	if(state.paginationReducer[ownProps.scope]) {
		filters = state.paginationReducer[ownProps.scope].filters;
	}

	return {
		applications: state.applicationReducer.applications,
		subApplications: state.applicationReducer.subApplications,
		seakeepers: state.seakeeperReducer.seakeepers,
		dealers: state.dealerReducer.dealers,
		builders: state.builderReducer.builders,
		models: state.modelReducer.models,
		filters: filters
	};
}

const mapDispatchToProps = {
	getApplications: applicationActions.get,
	getSeakeepers: seakeeperActions.get,
	getBuilders: builderActions.search,
	getModels: modelActions.search,
	filterUpdate: paginationActions.updateFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterMenu);
