import React, { Component } from 'react';
//import DealerLogo from '../assets/images/global/JCCY_logo.jpg';

class DealerPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			visible: false
		};

		this.toggleShow = this.toggleShow.bind(this);
	}

	toggleShow() {
		if (this.state.visible === false) {
			this.setState({
				visible: true
			});
		} else {
			this.setState({
				visible: false
			});
		}
	}
	render() {
		const { dealerLogo, dealerName, dealerContact, dealerAttributes, dealerRating, dealerContactLink } = this.props;

		let showRating = false;
		if(dealerRating > 0) {
			showRating = true;
		}

		var show = '';

		if (this.state.visible === true) {
			show = 'rf-show';
		}

		return (
			<>
				<button type="button" className="rf-dealer-name" onClick={this.toggleShow}>{dealerName}</button>
				<div className={"rf-dealer-popup " + show}>
					<button type="button" className="rf-close-dealer-popup" onClick={this.toggleShow}><span className="rf-screen-reader">Close</span></button>
					<div className="rf-dealer-content">
						<div className="rf-dealer-container">
							{dealerLogo &&
								<img src={dealerLogo} alt={dealerName + " logo"} className="rf-dealer-logo" />
							}
							{showRating &&
								<div className="rf-star-rating" data-stars={dealerRating}>
									<div className="rf-star-info">?<div className="rf-star-info-copy">Rating is based on Seakeeper’s customer feedback survey results.</div></div>
								</div>
							}
						</div>
						<div className="rf-dealer-container">
							<div className="rf-dealer-info">
								<p className="rf-dealer-name">{dealerName}</p>
								{dealerContact}
							</div>
						</div>
					</div>
					<div className="rf-dealer-footer">
						<ul>
							{dealerAttributes.map((attribute, index) => (
								<React.Fragment key={index}>
									{attribute.dealer_attribute_id === 1 &&
										<li className="icon-service-center">service-center</li>
									}
									{attribute.dealer_attribute_id === 2 &&
										<li className="icon-installation-center">installation-center</li>
									}
									{attribute.dealer_attribute_id === 3 &&
										<li className="icon-mobile-capabilities">mobile-capabilities</li>
									}
								</React.Fragment>
							))}
						</ul>
					</div>
					{dealerContactLink}
				</div>
			</>
		);
	}
}

export default DealerPopup;
