import history from '../history';
import { refitService } from '../services';

export const REFIT_SEARCH_REQUEST = 'REFIT_SEARCH_REQUEST';
export const REFIT_SEARCH_SUCCESS = 'REFIT_SEARCH_SUCCESS';
export const REFIT_SEARCH_FAILURE = 'REFIT_SEARCH_FAILURE';

export const REFIT_ADD_REQUEST = 'REFIT_ADD_REQUEST';
export const REFIT_ADD_SUCCESS = 'REFIT_ADD_SUCCESS';
export const REFIT_ADD_FAILURE = 'REFIT_ADD_FAILURE';

export const REFIT_GET_REQUEST = 'REFIT_GET_REQUEST';
export const REFIT_GET_SUCCESS = 'REFIT_GET_SUCCESS';
export const REFIT_GET_FAILURE = 'REFIT_GET_FAILURE';

export const REFIT_UPDATE_REQUEST = 'REFIT_UPDATE_REQUEST';
export const REFIT_UPDATE_SUCCESS = 'REFIT_UPDATE_SUCCESS';
export const REFIT_UPDATE_FAILURE = 'REFIT_UPDATE_FAILURE';

export const refitActions = {
	search,
	create,
	get,
	update
};

function search(page = 1, perPage = 10, filters = {}) {
	return (dispatch) => {
		dispatch(request());

		refitService.search(page, perPage, filters)
			.then(
				json => {
					dispatch(success(json));
				},
				error => {
					dispatch(failure(error));
					alert(error.join('\n'));
					console.log(error);
				}
			);
	};

	function request() {
		return {
			type: REFIT_SEARCH_REQUEST
		};
	}
	function success(json) {
		return {
			type: REFIT_SEARCH_SUCCESS,
			payload: {
				refits: json.data,
				totalCount: json.total
			}
		};
	}
	function failure(error) {
		return {
			type: REFIT_SEARCH_FAILURE,
			error: error
		};
	}
}

function create(refit) {
	return (dispatch) => {
		dispatch(request());

		refitService.create(refit)
			.then(
				json => {
					dispatch(success(json));

					let refit_id = json.data.refit_id || false;
					if(refit_id) {
						alert("Refit created successfully!");
						history.push('/refits-search/' + refit_id);
					}
				},
				error => {
					dispatch(failure(error));
					alert(error.join('\n'));
					console.log(error);
				}
			);
	};

	function request() {
		return {
			type: REFIT_ADD_REQUEST
		};
	}
	function success(json) {
		return {
			type: REFIT_ADD_SUCCESS,
			payload: {
				refit: json.data,
			}
		};
	}
	function failure(error) {
		return {
			type: REFIT_ADD_FAILURE,
			error: error
		};
	}
}

function get(refitID) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request());

			refitService.get(refitID)
				.then(
					json => {
						dispatch(success(json));
						resolve(json.data);
					},
					error => {
						dispatch(failure(error));
						alert(error.join('\n'));
						resolve();
					}
				);
		});
	};

	function request() {
		return {
			type: REFIT_GET_REQUEST
		};
	}
	function success(json) {
		return {
			type: REFIT_GET_SUCCESS,
			payload: {
				refit: json.data,
			}
		};
	}
	function failure(error) {
		return {
			type: REFIT_GET_FAILURE,
			error: error
		};
	}
}

function update(refit) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request());

			refitService.update(refit)
				.then(
					json => {
						dispatch(success(json));
						alert("Refit updated successfully!");
						resolve(json.data);
					},
					error => {
						dispatch(failure(error));
						alert(error.join('\n'));
						console.log(error);
						resolve();
					}
				);
		});
	};

	function request() {
		return {
			type: REFIT_UPDATE_REQUEST
		};
	}
	function success(json) {
		return {
			type: REFIT_UPDATE_SUCCESS,
			payload: {
				refit: json.data,
			}
		};
	}
	function failure(error) {
		return {
			type: REFIT_UPDATE_FAILURE,
			error: error
		};
	}
}
