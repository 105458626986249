import React, { Component } from 'react';

class PageSection extends Component {
    render() {
        return (
            <section className={"sk-page-section " + (this.props.className ? this.props.className : '')}>
                { (this.props.sectionTitle || this.props.sectionActions) &&
                    <div className="sk-page-section-header">
                        <h2 className={"sk-page-section-title " + (this.props.titleClassName ? this.props.titleClassName : '')}>{ this.props.sectionTitle }</h2>
                        <div className="sk-page-section-actions">
                            { this.props.sectionActions }
                        </div>
                    </div>
                }
                <div className="sk-page-section-content">
                    { this.props.children }
                </div>
            </section>
        );
    }
}

export default PageSection;
