import { authHeader } from '../helpers';
import queryString from 'query-string';

export const builderService = {
	search,
	get,
	update,
	create
};

function search(page, perPage, filters) {
	// const query = 'page=' + page + '&per_page=' + perPage + '&' + queryString.stringify(filters);
	// const requestURL = process.env.REACT_APP_API_URL + '/boat-builders?' + query;
	// const requestOptions = {
	// 	method: 'GET',
	// 	headers: authHeader()
	// };
	//
	// return fetch(requestURL, requestOptions).then(handleResponse);

	const requestBody = JSON.stringify({page: page, perPage: perPage, filters: filters});
	const requestURL = process.env.REACT_APP_API_URL + '/netsuite-refits/search';
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: requestBody
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function get(builderID) {
	// const requestURL = process.env.REACT_APP_API_URL + '/boat-builders/' + builderID + '?with_models=true';
	// const requestOptions = {
	// 	method: 'GET',
	// 	headers: authHeader()
	// };
	//
	// return fetch(requestURL, requestOptions).then(handleResponse);

	const requestURL = process.env.REACT_APP_API_URL + '/netsuite-refits/boat-builders/' + builderID + '?with_models=true';
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function update(builder) {
	const requestBody = JSON.stringify({builder: builder});
	const requestURL = process.env.REACT_APP_API_URL + '/boat-builders/' + builder.builder_id;
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: requestBody
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function create(builder) {
	const requestBody = JSON.stringify({builder: builder});
	const requestURL = process.env.REACT_APP_API_URL + '/boat-builders';
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: requestBody
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function handleResponse(response) {
	return response.json().then((json) => {
		if(json.success) {
			return json;
		} else {
			console.log('error json = ');
			console.log(json);
			return Promise.reject(json.messages);
		}
	});
}
