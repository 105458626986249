import { seakeeperService } from '../services';

export const SEAKEEPER_GET_REQUEST = 'SEAKEEPER_GET_REQUEST';
export const SEAKEEPER_GET_SUCCESS = 'SEAKEEPER_GET_SUCCESS';
export const SEAKEEPER_GET_FAILURE = 'SEAKEEPER_GET_FAILURE';

export const seakeeperActions = {
	get
};

function get() {
	return (dispatch) => {
		dispatch(request());

		seakeeperService.get()
			.then(
				json => {
					dispatch(success(json));
				},
				error => {
					dispatch(failure(error));
					console.log('error');
					console.log(error);
				}
			);
	};

	function request() {
		return {
			type: SEAKEEPER_GET_REQUEST
		};
	}
	function success(json) {
		return {
			type: SEAKEEPER_GET_SUCCESS,
			payload: {
				seakeepers: json.data
			}
		};
	}
	function failure(error) {
		return {
			type: SEAKEEPER_GET_FAILURE,
			error: error
		};
	}
}
