import React, { Component } from 'react';

class Input extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isFocus: false
		}

		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
	}

	onFocus() {
		this.setState({
			isFocus: true
		});
	}

	onBlur() {
		this.setState({
			isFocus: false
		});
	}

	onKeyDown(e) {

		if(this.props.isNumber) {
			console.log(e.key);
			// prevent commas from being entered to avoid confusion
			if(e.key === ',') {
				e.preventDefault();

				if(this.props.commaWarning) {
					this.props.commaWarning();
				}
			}
		}

		if(this.props.onKeyDown) {
			this.props.onKeyDown(e);
		}

	}

	render() {
		let displayValue = this.props.value;
		if(this.props.isNumber && this.props.value) {
			if(this.props.disabled) {
				let number = this.props.value * 1;
				let digits = this.props.numberOfDecimals || 0;
				displayValue = number.toFixed(digits);
			} else if( !this.state.isFocus) {
				// display a minimum of 2 decimal places
				let number = this.props.value * 1;
				let digits = this.props.numberOfDecimals || 0;
				displayValue = number.toFixed(Math.max(digits, (number.toString().split('.')[1] || []).length));
			}
		}

		return (
			<div className={"sk-input-wrapper sk-text-input-wrapper sk-input-type-" + this.props.type + (this.props.type === 'hidden' ? " sk-hidden-input-wrapper" : '') + (this.props.required ? " sk-required" : '') + (this.props.error ? " sk-error" : "")}>
				<label
					className="sk-input-label"
					htmlFor={this.props.htmlID}
				>
					{this.props.label}
					{(this.props.required ? "*" : '')}
				</label>
				<input
					type={this.props.type}
					id={this.props.htmlID}
					name={this.props.htmlName}
					className={"sk-input-text " + (this.props.className ? this.props.className : '')}
					placeholder={this.props.placeholderText}
					onChange={this.props.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onKeyDown={this.onKeyDown}
					maxLength={this.props.maxLength}
					disabled={this.props.disabled}
					required={this.props.required}
					value={displayValue || ''}
				/>
			</div>
		);
	}
}

export default Input;
