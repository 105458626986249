import { combineReducers } from 'redux';
import { authentication } from './auth.reducer.js';
import { refitReducer } from './refit.reducer.js';
import { applicationReducer } from './application.reducer.js';
import { seakeeperReducer } from './seakeeper.reducer.js';
import { dealerReducer } from './dealer.reducer.js';
import { builderReducer } from './builder.reducer.js';
import { refitBoatReducer } from './refitBoat.reducer.js';
import { modelReducer } from './model.reducer.js';
import { seaTrialReducer } from './seaTrial.reducer.js';
import { sizingFormReducer } from './sizingForm.reducer.js';
import { paginationReducer } from './pagination.reducer.js';
import { bmyReducer } from './bmy.reducer.js';

const rootReducer = combineReducers({
	authentication,
	refitReducer,
	applicationReducer,
	seakeeperReducer,
	dealerReducer,
	builderReducer,
	refitBoatReducer,
	modelReducer,
	seaTrialReducer,
	sizingFormReducer,
	paginationReducer,
	bmyReducer
});

export default rootReducer;
