import * as Actions from '../actions';

const initialDealerState = {
	dealers: [],
	loading: false
};

export const dealerReducer = (state = initialDealerState, action) => {
	switch(action.type) {
		case Actions.DEALER_SEARCH_REQUEST:
			return {
				...state,
				loading: true
			};
		case Actions.DEALER_SEARCH_SUCCESS:
			return {
				...state,
				loading: false,
				dealers: action.payload.dealers,
				totalCount: action.payload.totalCount
			};
		default:
			return state;
	}
};
