import React, { Component } from 'react';
import Boat from '../assets/images/global/boat.png';
import Page from '../components/Page';
import Table from '../components/Table';
import PageSection from '../components/PageSection';
import NoResults from '../components/NoResults';
import DealerPopup from '../components/DealerPopup';
import { connect } from 'react-redux'
import { refitActions, seaTrialActions, modelActions, sizingFormActions } from '../actions';
import renderHTML from 'react-render-html';
import seatrialImage from '../assets/images/global/seatrial-image.png';
import { USER_ROLES } from '../constants';

class RefitSingle extends Component {
	constructor(props) {
		super(props);

		this.state = {
			refitsTableHeading: [
				'Brand',
				'Model',
				'Year',
				'Seakeeper',
				'Sea Trial',
				'Dealer',
				'Dealer Location',
				''
			],
			seaTrialsTableHeading: [
				'Image',
				'Brand',
				'Model',
				'Seakeeper',
				'Date',
				''
			],
			sizingFormTableHeading: [
				'Brand',
				'Model',
				'Year',
				'Date',
				''
			],
			showInternal: false,
			sizingFormGet: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		const { builder_model_id } = this.props.match.params;
		const builder_id = builder_model_id.split('-')[0];
		const model_id = builder_model_id.split('-')[1];
		this.props.modelGet(model_id, builder_id);
		this.props.search(1, 100, { model_id, builder_id });
		//this.props.seaTrialsSearch({ model_id });

		if(this.props.user.pp_role_id <= USER_ROLES.DEALER) {
			let internalRefitHeadings = [
				'Brand',
				'Model',
				'Year',
				'Seakeeper',
				'Sea Trial',
				'Install Photos',
				'Dealer',
				'Dealer Location',
				'Install Location',
			];
			this.setState({
				showInternal: true,
				refitsTableHeading: internalRefitHeadings
			});
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (this.props.model.hasOwnProperty('builder_name') === true && this.state.sizingFormGet === false) {
			this.props.sizingFormSearch({ netsuite_model: this.props.model.model_name });
			this.setState({
				sizingFormGet: true
			});
		}
	}

	render() {
		const { refits, user, loading, seaTrials, model, sizingForms } = this.props;
		const { showInternal } = this.state;
		var seaTrialLinks = [];
		var drawingsList = [];
		var showPerformance = false;
		var boatImage = false;
		let showHours = false;
		const loadingMessage = <p className="sk-loading">Loading</p>;
		let pageHeader = '';
		let contact_url = 'https://855014.extforms.netsuite.com/app/site/crm/externalleadpage.nl/compid.855014/.f?formid=90&h=AAFdikaIP-lRVJCqn5iUIwBv-ancxBWGTZ2BkR2rm_OWDX-nuV8&redirect_count=1&did_javascript_redirect=T';
		let contact_url_fields = '';
		let contact_url_header_fields = '';
		if(model && model.builder) {
			pageHeader = model.builder.builder_name + ' ' + model.model_name;
			contact_url_header_fields = '&custentity255=' + model.builder.builder_name + '&custentity256=' + model.model_name;
		}
		let installDrawings = false;

		let refitsArray = [];
		var skArray = [];
		let row = [];
		let dealer_address = '';
		let install_location = '';
		let sea_trial = '';
		for(let i=0; i < refits.length; i++) {
			install_location = '';
			sea_trial = '';
			skArray = [];

			if (refits[i].builder_name != '') {
				contact_url_fields += '&custentity255=' + refits[i].builder_name;
			}
			if (refits[i].model_name != '') {
				contact_url_fields += '&custentity256=' + refits[i].model_name;
			}
			if (refits[i].boat_year != '') {
				contact_url_fields += '&custentity204=' + refits[i].boat_year;
			}

			if(refits[i].image_url !== '') {
				installDrawings = <a href={refits[i].image_url} target="_blank">View</a>;
			} else {
				installDrawings = '';
			}

			if (refits[i].seatrial !== '') {
				sea_trial = <a href={refits[i].seatrial} target="_blank">View</a>;
			}

			var skModels = renderHTML('<div>' + refits[i].sk_models.split('and').join('</div><div>') + '</div>');

			if(showInternal) {
				refitsArray.push([
					refits[i].builder_name,
					refits[i].model_name,
					refits[i].boat_year,
					skModels,
					sea_trial,
					installDrawings,
					refits[i].dealer_name,
				  	refits[i].dealer_address,
				 	refits[i].install_location
				]);


			} else {
				row = [
					refits[i].builder_name,
					refits[i].model_name,
					refits[i].boat_year,
					skModels,
					sea_trial,
				];

				if(refits[i].dealer_name !== "") {
					row.push(refits[i].dealer_name);
				} else {
					row.push('Not Available')
				}

				row.push(refits[i].dealer_address);

				if(refits[i].dealer_email !== "") {
					row.push(<a href={contact_url + contact_url_fields}  target="_blank">Contact Dealer</a>);
				} else {
					row.push(<a href={contact_url + contact_url_fields} target="_blank">Contact Seakeeper</a>);
				}

				refitsArray.push(row);
			}

		}

		let seaTrialsArray = [];
		let location = '';
		skArray = [];

		let sizingFormArray = [];
		if(showInternal) {
			for(let i = 0; i < sizingForms.length; i++) {
				sizingFormArray.push([
					sizingForms[i].builder_name,
					sizingForms[i].model_name,
					sizingForms[i].boat_year,
					sizingForms[i].created_date.split(' ')[0],
					<a href={process.env.REACT_APP_SIZING_FORM_HOST + "/report/" + sizingForms[i].report_id} target="_blank">See Details</a>,
				]);
			}
		}

		return (
			<div className="rf-refits-single rf-single-model rf-refits-webview">

				<PageSection>
					<div className="rf-single-header">
						<a href="/refit" className="rf-page-back">‹ Refits Database</a>
						<h1 className="rf-heading-1">{pageHeader}</h1>
					</div>


					<>
						<header className="rf-table-header">
							<h2 className="rf-heading-3">Refits</h2>
							<p className="rf-text">Don't see a dealer near you? <a href={contact_url + contact_url_header_fields} target="_blank">Contact Us</a></p>
						</header>
						<Table
							tableHeading={this.state.refitsTableHeading}
							tableBody={refitsArray}
							noResultsMessage="No Results Found"
							loadingMessage={loadingMessage}
							loading={loading}
						/>

					{seaTrialsArray.length > 0 &&
						<div>
							<h2 className="rf-heading-3">Sea Trials</h2>
							<div className="sk-trial-results">
								{seaTrialsArray}
							</div>
						</div>
					}

					{showInternal && sizingFormArray.length > 0 &&
						<div>
							<h2 className="rf-heading-3">Sizing Forms</h2>
							<Table
								tableHeading={this.state.sizingFormTableHeading}
								tableBody={sizingFormArray}
								noResultsMessage="No Results Found"
								loadingMessage={loadingMessage}
								loading={loading}
							/>
						</div>
					}
					</>

				</PageSection>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		refits: state.refitReducer.refits,
		seaTrials: state.seaTrialReducer.seaTrials,
		loading: state.refitReducer.loading,
		user: state.authentication.user,
		model: state.modelReducer.model,
		sizingForms: state.sizingFormReducer.sizingForms
	};
}

const mapDispatchToProps = {
	modelGet: modelActions.get,
	get: refitActions.get,
	search: refitActions.search,
	seaTrialsSearch: seaTrialActions.search,
	sizingFormSearch: sizingFormActions.search
};

export default connect(mapStateToProps, mapDispatchToProps)(RefitSingle);
