import * as Actions from '../actions';

const initialPaginationState = {};
const initialSinglePaginationState = {
	page: 1,
	perPage: 25,
	filters: {}
};

export const paginationReducer = (state = initialPaginationState, action) => {
	switch(action.type) {
		case Actions.PAGINATION_INIT:
			return {
				...state,
				[action.payload.scope]: initialSinglePaginationState
			}

		case Actions.PAGINATION_SET_PAGINATION:
			return {
				...state,
				[action.payload.scope]: {
					...state[action.payload.scope],
					perPage: action.payload.perPage,
					page: action.payload.page,
					filters: action.payload.filters,
					hiddenFilters: action.payload.hiddenFilters,
				}
			}

		case Actions.PAGINATION_SET_PAGE:
			return {
				...state,
				[action.payload.scope]: {
					...state[action.payload.scope],
					page: action.payload.page
				}
			}

		case Actions.PAGINATION_SET_PER_PAGE:
			return {
				...state,
				[action.payload.scope]: {
					...state[action.payload.scope],
					page: 1,
					perPage: action.payload.perPage
				}
			}

		case Actions.PAGINATION_SET_FILTERS:
			return {
				...state,
				[action.payload.scope]: {
					...state[action.payload.scope],
					filters: action.payload.filters
				}
			}

		case Actions.PAGINATION_UPDATE_FILTER:
			if(action.payload.value === '') {
				delete state[action.payload.scope].filters[action.payload.name];
				return {
					...state,
					[action.payload.scope]: {
						...state[action.payload.scope],
						filters: {
							...state[action.payload.scope].filters
						}
					}
				}
			} else {
				return {
					...state,
					[action.payload.scope]: {
						...state[action.payload.scope],
						filters: {
							...state[action.payload.scope].filters,
							[action.payload.name]: action.payload.value
						}
					}
				};
			}

		case Actions.PAGINATION_RESET:
			return {
				...state,
				[action.payload.scope]: {
					...state[action.payload.scope],
					...initialSinglePaginationState,
				}
			};

		default:
			return state;
	}
};
