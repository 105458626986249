import history from '../history';
import { authService } from '../services';

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const authActions = {
	login,
	logout
};

function login(username, password) {
	return dispatch => {
		dispatch(request({ username }));

		authService.login(username, password)
			.then(
				user => {
					dispatch(success(user));
					history.push('/refits-search');
				},
				error => {
					dispatch(failure(error));
					alert(error.join('\n'));
					console.log(error);
				}
			);
	};

	function request(user) {
		return {
			type: AUTH_LOGIN_REQUEST,
			payload: { user }
		}
	}
	function success(user) {
		return {
			type: AUTH_LOGIN_SUCCESS,
			payload: { user }
		}
	}
	function failure(error) {
		return {
			type: AUTH_LOGIN_FAILURE,
			payload: { error },
			error: true
		}
	}
}

function logout() {
	authService.logout();
	history.push('/login');
	return {
		type: AUTH_LOGOUT
	};
}
