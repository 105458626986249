import * as Actions from '../actions';

const initialRefitState = {
	refits: [],
	loading: false,
	refit: {}
};

export const refitReducer = (state = initialRefitState, action) => {
	switch(action.type) {
		case Actions.REFIT_SEARCH_REQUEST:
			return {
				...state,
				loading: true
			};
		case Actions.REFIT_SEARCH_SUCCESS:
			return {
				...state,
				loading: false,
				refits: action.payload.refits,
				totalCount: action.payload.totalCount
			};
		case Actions.REFIT_ADD_REQUEST:
			return {
				...state,
				submitted: true
			};
		case Actions.REFIT_ADD_SUCCESS:
			return {
				...state,
				submitted: false,
				refit: action.payload.refit
			};
		case Actions.REFIT_GET_REQUEST:
			return {
				...state,
				loading: true
			};
		case Actions.REFIT_GET_SUCCESS:
			return {
				...state,
				loading: false,
				refit: action.payload.refit
			};
		case Actions.REFIT_UPDATE_REQUEST:
			return {
				...state,
				submitted: true
			};
		case Actions.REFIT_UPDATE_SUCCESS:
			return {
				...state,
				submitted: false,
				refit: action.payload.refit
			};
		default:
			return state;
	}
};
