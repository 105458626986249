import React, { Component } from 'react';
import Input from './Input';
import Select from './Select';
import { connect } from 'react-redux';
import { seakeeperActions } from '../actions';
import blankConfig from '../assets/images/global/blank-config.png';

class ConfigurationGroup extends Component {
		constructor(props) {
			super(props);

			this.addSeakeeper = this.addSeakeeper.bind(this);
			this.changeModel = this.changeModel.bind(this);
			this.changeNumberGyros = this.changeNumberGyros.bind(this);
			this.incrementGyros = this.incrementGyros.bind(this);
			this.decrementGyros = this.decrementGyros.bind(this);
			this.deleteModel = this.deleteModel.bind(this);
		}

		componentDidMount() {
			this.props.getSeakeepers();
		}

		addSeakeeper(configIndex) {
			let tempConfigData = [...this.props.configInfo];

			tempConfigData.push({ sk_model_id: '', sk_model_count: '' });
			this.props.onChange(tempConfigData);
		}

		deleteModel(e) {
			let target = e.target;
			let skIndex = target.dataset.skindex;
			let tempConfigData = [...this.props.configInfo];

			tempConfigData.splice(skIndex, 1);
			this.props.onChange(tempConfigData);
		}

		changeModel(e) {
			let model = e.target.value * 1;
			let targetName = e.target.name;
			let skIndex = targetName.split("_")[1];
			let tempConfigData = [...this.props.configInfo];

			tempConfigData[skIndex].sk_model_id = model;
			tempConfigData[skIndex].sk_model_count = 1;
			this.props.onChange(tempConfigData);
		}

		changeNumberGyros(e) {
			let numGyros = e.target.value * 1;
			let targetName = e.target.name;
			let skIndex = targetName.split("_")[1];
			let tempConfigData = [...this.props.configInfo];

			if (!Number.isInteger(numGyros)) {
				numGyros = 0;
			}

			if (numGyros > 99) {
				numGyros = 99;
			} else if (numGyros < 0) {
				numGyros = 0;
			}

			tempConfigData[skIndex].sk_model_count = numGyros;
			this.props.onChange(tempConfigData);
		}

		incrementGyros(e) {
			let target = e.target;
			let skIndex = target.dataset.skindex;
			let tempConfigData = [...this.props.configInfo];

			let curNumGyros = tempConfigData[skIndex].sk_model_count * 1;
			let newNumGyros = curNumGyros + 1;
			if (newNumGyros > 99) {
				newNumGyros = 99;
			}

			tempConfigData[skIndex].sk_model_count = newNumGyros;
			this.props.onChange(tempConfigData);
		}

		decrementGyros(e) {
			let target = e.target;
			let skIndex = target.dataset.skindex;
			let tempConfigData = [...this.props.configInfo];

			let curNumGyros = tempConfigData[skIndex].sk_model_count * 1;
			let newNumGyros = curNumGyros - 1;
			if (newNumGyros < 0) {
				newNumGyros = 0;
			}

			tempConfigData[skIndex].sk_model_count = newNumGyros;
			this.props.onChange(tempConfigData);
		}

		render() {
			let { configInfo, seakeepers } = this.props;

			// convert lists to option lists for select element
			let seakeeperOptions = [],
				seakeeperImages = [];

			for(let i=0; i < seakeepers.length; i++) {
				seakeeperOptions.push({
					value: seakeepers[i].seakeeper_id,
					text: seakeepers[i].seakeeper_model
				});
				seakeeperImages[seakeepers[i].seakeeper_id] = (
					<img
						src={'http://api-dev.seakeeper.com/' + seakeepers[i].image_url}
						alt={seakeepers[i].seakeeper_model}
						className="sk-seakeeper-image sk-show"
						data-id={seakeepers[i].seakeeper_id}
					/>
				);
			}

			let blankImage = <img
				src={blankConfig}
				alt="Seakeeper Placeholder"
				className="sk-seakeeper-image sk-show"
			/>

			for(let a = 0; a < configInfo.length; a++) {
				if(configInfo[a].sk_model_id) {
					configInfo[a].sk_image = seakeeperImages[configInfo[a].sk_model_id];
				} else {
					configInfo[a].sk_image = blankImage;
				}
			}

			return (
				<div className="sk-configuration-form">
					<div className="sk-configuration-group">
						{configInfo &&
							configInfo.map((data, index) => (
								<div className="sk-config-field-group" key={index}>
									<div className="sk-seakeeper-display">
										{data.sk_image}
									</div>
									<Select
										label="Seakeeper"
										htmlName={"seakeeperModel_" + index}
										value={data.sk_model_id || ""}
										placeholderText="a Model"
										options={seakeeperOptions}
										onChange={this.changeModel}
										disabled={this.props.disableAll}
									/>
									<div className="sk-input-controls">
										<label className="sk-input-label">Quantity</label>
										{!this.props.disableAll &&
											<button
												type="button"
												className="sk-input-decrement"
												onClick={this.decrementGyros}
												data-skindex={index}>-</button>
										}
										<Input
											type="text"
											htmlName={"numGyros_" + index}
											value={data.sk_model_count || ""}
											placeholderText="0"
											onChange={this.changeNumberGyros}
											disabled={this.props.disableAll}
										/>
										{!this.props.disableAll &&
											<button
												type="button"
												className="sk-input-increment"
												onClick={this.incrementGyros}
												data-skindex={index}>+</button>
										}
									</div>
									{!this.props.disableAll &&
										<button
											className="sk-delete-group"
											onClick={this.deleteModel}
											data-skindex={index}>
											<span className="sk-button-text">Delete Model</span>
										</button>
									}
								</div>
							))
						}
						{(this.props.configInfo.length < 2) &&
							<button className="sk-add-seakeeper" onClick={this.addSeakeeper}>Add Additional <br />Seakeeper Model</button>
						}
					</div>
				</div>
			);
		}
}

function mapStateToProps(state) {
	return {
		seakeepers: state.seakeeperReducer.seakeepers
	};
}

const mapDispatchToProps = {
	getSeakeepers: seakeeperActions.get
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationGroup);
