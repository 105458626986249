import { dealerService } from '../services';

export const DEALER_SEARCH_REQUEST = 'DEALER_SEARCH_REQUEST';
export const DEALER_SEARCH_SUCCESS = 'DEALER_SEARCH_SUCCESS';
export const DEALER_SEARCH_FAILURE = 'DEALER_SEARCH_FAILURE';

export const dealerActions = {
	search
};

function search(page = 1, perPage = 5) {
	return (dispatch) => {
		dispatch(request());

		dealerService.search(page, perPage)
			.then(
				json => {
					dispatch(success(json));
				},
				error => {
					dispatch(failure(error));
					alert(error.join('\n'));
					console.log(error);
				}
			);
	};

	function request() {
		return {
			type: DEALER_SEARCH_REQUEST
		};
	}
	function success(json) {
		return {
			type: DEALER_SEARCH_SUCCESS,
			payload: {
				dealers: json.data
			}
		};
	}
	function failure(error) {
		return {
			type: DEALER_SEARCH_FAILURE,
			error: error
		};
	}
}
