import React, { Component } from 'react';
import { connect } from 'react-redux'
import { builderActions, paginationActions } from '../actions';
import { builderService } from '../services';

class BuilderFilter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedApplication: "1",
			hideBuilders: true,
			builderList: [],
			focusList: [],
			activeItem: -1
		}

		this.activeItemElement = null;

		this.handleChange = this.handleChange.bind(this);
		this.filterBuilders = this.filterBuilders.bind(this);
		this.selectBuilder = this.selectBuilder.bind(this);
		this.keyPress = this.keyPress.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.hoverItem = this.hoverItem.bind(this);
	}

	componentDidMount() {
		this.props.getBuilders();
	}

	handleChange(e) {
		const { name, value } = e.target;
		this.props.filterUpdate(this.props.scope, name, value);
	}

	handleBlur(e) {
		console.log('BLUR', e.target);
		setTimeout(() => {
			this.setState({
				hideBuilders: true
			});
		}, 125);
	}

	handleFocus(e) {
		this.setState({
			hideBuilders: false,
			activeItem: -1
		});
	}

	keyPress(e) {
		let { activeItem } = this.state;
		const { builderList } = this.state;
		let listSize = builderList.length;

		let key = (e.keyCode || e.which),
			down = 40,
			up = 38,
			enter = 13,
			target = e.target;

		switch (key) {
			case down:
				if (activeItem < (listSize - 1)) {
					activeItem += 1;
					this.setState({
						activeItem: activeItem
					});
				}
				e.preventDefault();
				break;

			case up:
				if (activeItem > 0) {
					activeItem -= 1;
					this.setState({
						activeItem: activeItem
					});
				}
				e.preventDefault();
				break;

			case enter:
				e.preventDefault();

				if(activeItem > -1) {
					this.selectBuilder({ target: this.activeItemElement });
				} else {
					this.props.applyFilters();
					e.target.blur();
				}
				break;

			default:
				this.filterBuilders(e);
				break;
		}
	}

	filterBuilders(e) {
		const { value } = e.target;
		const { builderList } = this.state;
		var maxListSize = 10;
		var tempList = [];

		if(value != '') {
			this.setState({
				loading: true
			});

			builderService.search(1, 10, { builder_name: value, with_models: true })
				.then(
					json => {
						this.setState({
							builderList: json.data,
							loading: false,
							hideBuilders: false
						});
					},
					error => {
						console.log('error');
						console.log(error);
					}
				);
		}
	}

	selectBuilder(event) {
		var builder = event.target.textContent,
			builder_id = event.target.getAttribute('data-id');

		this.props.filterUpdate(this.props.scope, "builder_name", builder).then(this.props.applyFilters);

		this.setState({
			hideBuilders: true
		});
	}

	hoverItem(e) {
		let target = e.target,
			activeItem = (e.target.dataset.index * 1);

		// this.setState({
		// 	activeItem: activeItem
		// });
	}

	render() {
		const { filters } = this.props;

		return (
			<div className="autocomplete-wrapper">
				<input
					type="text"
					className="sk-input"
					placeholder="Builder"
					onKeyUp={this.keyPress}
					onBlur={this.handleBlur}
					onFocus={this.handleFocus}
					//onKeyPress={this.keyPress}
					onChange={this.handleChange}
					name="builder_name"
					value={filters.builder_name || ''}
					autofill="off"
					autoComplete="off"
				/>
				{this.state.builderList.length > 0 && this.state.hideBuilders === false &&
					<div className="sk-autocomplete-list">
						{this.state.builderList.map((builder, index) => (
							<p
								key={index}
								data-index={index}
								ref={(index === this.state.activeItem ? element => this.activeItemElement = element : '')}
								className={"sk-list-item " + (index === this.state.activeItem ? "sk-active" : '')}
								onClick={this.selectBuilder}
								onMouseEnter={this.hoverItem}
								data-id={builder.builder_id}
							>{builder.builder_name}</p>
						))}
					</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	let filters = {};
	if(state.paginationReducer[ownProps.scope]) {
		filters = state.paginationReducer[ownProps.scope].filters;
	}

	return {
		builders: state.builderReducer.builders,
		filters: filters
	};
}

const mapDispatchToProps = {
	getBuilders: builderActions.search,
	filterUpdate: paginationActions.updateFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(BuilderFilter);
