import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authActions } from '../actions';
import PageSection from '../components/PageSection';
import Input from '../components/Input';
import Page from '../components/Page';

class LoginPage extends Component {
	constructor(props) {
		super(props);

		//this.props.logout();

		this.state = {
			username: '',
			password: '',
			submitted: false
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleInputChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleSubmit(e) {
		e.preventDefault();

		this.setState({ submitted: true });
		const { username, password } = this.state;
		if(username && password) {
			this.props.login(username, password);
		}
	}

	render() {
		const { username, password } = this.state;

		return (
			<Page>
				<PageSection className="sk-user-flow">

					<form id="login" className="sk-form-wrapper" onSubmit={this.handleSubmit}>
						<div className="sk-form-row">
							<Input
								type="text"
								htmlName="username"
								label="Email Address"
								placeholderText="Email"
								onChange={this.handleInputChange}
								value={username}
							/>
						</div>
						<div className="sk-form-row">
							<Input
								type="password"
								htmlName="password"
								label="Password"
								placeholderText="Password"
								onChange={this.handleInputChange}
								value={password}
							/>
						</div>

						<div className="sk-action-buttons">
							<button type="submit" id="login-button" className={"sk-button" + (this.props.loggingIn === true ? " sk-loading" : "")}>Log In</button>
							<p className="sk-instruction">or</p>
							<a href="/register" className="sk-register-button">Register</a>
							<a href="/password-reset" className="sk-forgot-password-button">Forgot Your Password?</a>
						</div>
					</form>

				</PageSection>
			</Page>
		);
	}
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}

const mapDispatchToProps = {
	login: authActions.login,
	logout: authActions.logout
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
//export default LoginPage;
