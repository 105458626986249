import { authHeader } from '../helpers';
import queryString from 'query-string';

export const refitBoatService = {
	search,
	get
};

function search(page, perPage, filters) {
	const query = 'page=' + page + '&per_page=' + perPage + '&' + queryString.stringify(filters);
	const requestURL = process.env.REACT_APP_API_URL + '/refit-boats?' + query;
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function get(boatID) {
	const requestURL = process.env.REACT_APP_API_URL + '/refit-boats/' + boatID;
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function handleResponse(response) {
	return response.json().then((json) => {
		if(json.success) {
			return json;
		} else {
			return Promise.reject(json.messages);
		}
	});
}
