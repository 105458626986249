import React, { Component } from 'react';

class NoResults extends Component {
    render() {
        return (
            <div className="sk-no-results">
                <div className="sk-message">{this.props.message}</div>
            </div>
        );
    }
}

export default NoResults;
