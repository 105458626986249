import { authHeader } from '../helpers';
import queryString from 'query-string';

export const refitService = {
	search,
	create,
	get,
	update
};

function search(page, perPage, filters) {
	// const query = 'page=' + page + '&per_page=' + perPage + '&' + queryString.stringify(filters);
	// const requestURL = process.env.REACT_APP_API_URL + '/refits?' + query;
	// const requestOptions = {
	// 	method: 'GET',
	// 	headers: authHeader()
	// };
	//
	// return fetch(requestURL, requestOptions).then(handleResponse);
	const query = 'page=' + page + '&per_page=' + perPage + '&' + queryString.stringify(filters);
	const requestURL = process.env.REACT_APP_API_URL + '/netsuite-refits/refits?' + query;
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function create(refit) {
	const requestBody = JSON.stringify({refit: refit});
	const requestURL = process.env.REACT_APP_API_URL + '/refits';
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: requestBody
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function get(refitID) {
	const requestURL = process.env.REACT_APP_API_URL + '/refits/' + refitID;
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function update(refit) {
	const requestBody = JSON.stringify({refit: refit});
	const requestURL = process.env.REACT_APP_API_URL + '/refits/' + refit.refit_id;
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: requestBody
	};

	return fetch(requestURL, requestOptions).then(handleResponse);
}

function handleResponse(response) {
	return response.json().then((json) => {
		if(json.success) {
			return json;
		} else {
			return Promise.reject(json.messages);
		}
	});
}
